.active-menu{
  display: block;
}
.inactive-menu{
  display: none;
}

html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  height: auto;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased
}

ul,ol {
  padding-left: 1.5em
}

ul {
  list-style-type: disc
}

ol {
  list-style-type: decimal
}

ul ul,ol ul {
  list-style-type: circle
}

ol ol,ul ol {
  list-style-type: lower-latin
}

a,button {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent
}

a:focus:not(:focus-visible) {
  outline: none
}

.breadcrumbs {
  margin-bottom: 1em;
  margin-top: -1.5rem
}

@media (max-width: 960px) {
  .breadcrumbs .breadcrumbs {
      margin-top:-0.5rem
  }
}

.breadcrumbs ol {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex
}

.breadcrumbs__separator {
  margin: 0 .4em
}

.breadcrumbs__link--current {
  color: inherit;
  text-decoration: none
}



.clear:before,.clear:after {
  display: table;
  content: '';
  line-height: 0
}

.clear:after {
  clear: both
}

.menu>.menu-item {
  background: transparent
}

.menu-link {
  color: #a3a3a3
}

.menu-link:hover {
  color: #eb8113;
  background: transparent
}


.icon-badge-wrapper {
  position: relative;
  display: inline-block;
  line-height: 1em
}

.icon-badge {
  background: #eb8113;
  color: #fff;
  display: block;
  border-radius: 50%;
  font-size: .6em;
  font-family: monospace;
  width: 1.7em;
  height: 1.7em;
  position: absolute;
  top: -0.9em;
  right: -0.7em;
  text-align: center;
  line-height: 1.8em;
  font-weight: bold;
  transition: 200ms ease
}



.menu-link:hover .icon-badge, .icon-badge {
  background: #f09a41
}

.menu-cta .btn__default-color.btn-style-default {
  border: none
}

.menu-cta .btn__default-color {
  outline-color: #eb8113
}

.menu-cta .btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.menu-cta .btn__default-color.btn-style-flat:hover:not(:disabled),.menu-cta .btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.menu-cta .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.menu-cta .btn__default-color.btn-style-border:hover:not(:disabled),.menu-cta .btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.menu-cta .btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.menu-cta .btn__default-color.btn-style-default:hover:not(:disabled),.menu-cta .btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.menu-cta .btn--default-color {
  color: #eb8113
}

.menu {
  display: flex;
  flex-wrap: wrap;

  font-size: 1.5rem;
  font-family: "Source Sans Pro","sans-serif"
}

.menu:before,.menu:after {
  display: table;
  content: '';
  line-height: 0
}

.menu:after {
  clear: both
}

.menu {
  text-transform: uppercase
}

.menu{
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px;
  right:0;
}

.menu-item,.menu-link {
  position: relative;
  box-sizing: border-box;
  height: 4.9vh;
}

.menu-item:hover>.submenu,.menu-item.menu-is-hover>.submenu {
  display: block
}

.menu-is-desktop .submenu {
  position: absolute;
  z-index: 1500;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  border-radius: 0
}

.menu-is-desktop .submenu .menu-item>.menu-link {
  border-radius: 0
}

.menu-is-desktop .submenu .menu-item:not(:first-child)>.menu-link {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.menu-is-desktop .submenu .menu-item:not(:last-child)>.menu-link {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.submenu {
  display: none;
  font-family: "Source Sans Pro","sans-serif";
  font-size: 1.5rem
}

.submenu .menu-item {
  display: list-item;
  width: 100%
}

.submenu .menu-link {
  white-space: nowrap;
  max-width: 20em;
  min-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 300ms ease
}

.submenu .menu-has-submenu .menu-link {
  padding-right: 1.8em
}

.submenu .menu-has-submenu .arrow {
  position: absolute;
  right: 12px
}

.submenu .arrow:after {
  font-family: Times, Serif;
  content: 'â–¸'
}

.submenu >.menu-link {
  text-decoration: underline;
  border-bottom: 0
}

.menu-link {
  display: block;
  padding: 5px 12px;
  text-decoration: none;
  transition: 200ms ease
}

.menu-link:hover {
  text-decoration: none
}

.arrow {
  display: inline-block
}

.arrow:after {
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  font-family: Arial, Helvetica, Times, serif;
  margin-left: .4em
}

.menu-horizontal>.menu-item {
  display: block
}

.menu-horizontal .arrow-toplevel:after {
  font-family: Times, Serif;
  content: 'â–¾'
}

.menu-horizontal .submenu {
  top: 100%;
  left: 0
}

.menu-horizontal .submenu .submenu {
  left: 100%;
  top: 0
}

.menu-vertical {
  flex-direction: column
}

.menu-vertical>.menu-item {
  text-overflow: ellipsis
}

.menu-vertical .submenu {
  left: 100%;
  top: 0
}

.menu-vertical>.menu-item.menu-has-submenu>.menu-link {
  padding-right: 1.5em
}

.menu-vertical .arrow-toplevel {
  position: absolute;
  right: .8em
}

.menu-vertical .arrow-toplevel:after {
  font-family: Times, Serif;
  content: 'â–¸'
}

.menu-is-collapsed .menu-collapse {
  display: none !important
}


.menu-is-opened {
  display: block
}

.menu-vertical>.submenu>.menu-is-overflowing>.submenu,.submenu>.menu-is-overflowing>.submenu {
  left: auto;
  right: 100%
}

.menu-clone {
  display: none
}

.menu-is-desktop .menu-clone {
  display: none !important
}



.menu>.menu-item.menu-cta {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto
}

.menu-vertical.menu>.menu-item.menu-cta {
  margin-left: 0
}

.menu>.menu-item.menu-cta .btn {
  margin-left: 1em
}

.menu-vertical.menu>.menu-item.menu-cta .btn {
  margin-left: 0;
  margin-top: 1em
}

body>div {
  font-family: "Source Sans Pro","sans-serif";
  line-height: 1.5;
  font-size: 1.5rem;
  color: #707070
}

body>div .icon-image__inner {
  background-color: #bd9e7c
}

body>div .image-is-square .icon-image,body>div .image-is-rounded .icon-image,body>div .image-is-round .icon-image {
  background-color: #eb8113
}

body>div .image-is-square .icon-image__inner,body>div .image-is-rounded .icon-image__inner,body>div .image-is-round .icon-image__inner {
  background-color: #f9f9f9
}

@media (max-width: 960px) {
  body>div {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  body>div {
      line-height:1.35;
      font-size: 1.37rem
  }
}

body>div a {
  color: #eb8113;
  text-decoration: none
}

body>div a:hover {
  color: #f09a41;
  text-decoration: underline
}

body>div p,body>div ol,body>div ul {
  margin-top: 0;
  margin-bottom: .4em
}

body>div span[style*='color'] a {
  color: inherit
}

body>div button {
  line-height: 1.5
}

body>div .text-small {
  font-size: 1.05rem
}

body>div .accent-text-color {
  color: #eb8113
}

body>div .accent-background {
  color: #fff;
  background-color: #eb8113
}

body>div .accent-background a {
  color: #fff
}

body>div .accent-border-link-color {
  border-color: #eb8113
}

body>div .accent-border-color {
  border-color: #707070
}

.section h1,.section h2,.section h3,.section h4,.section h5,.section h6 {
  line-height: 1.1;
  color: #448f0b;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .section h1,.section h2,.section h3,.section h4,.section h5,.section h6 {
      line-height:1.1
  }
}

@media (max-width: 960px) {
  .section h1,.section h2,.section h3,.section h4,.section h5,.section h6 {
      line-height:1.1
  }
}

.section h1 a,.section h2 a,.section h3 a,.section h4 a,.section h5 a,.section h6 a {
  text-decoration: underline;
  color: #eb8113
}

.section h1 a:hover,.section h2 a:hover,.section h3 a:hover,.section h4 a:hover,.section h5 a:hover,.section h6 a:hover {
  color: #f09a41
}

.section .product-page .product__price__price {
  color: #eb8113
}

.section .heading-200 {
  font-size: 6rem;
  margin-top: 3.3rem;
  margin-bottom: 1.2rem
}

@media (max-width: 960px) {
  .section .heading-200 {
      font-size:4.5rem;
      margin-top: 2.85rem;
      margin-bottom: 1.05rem
  }
}

@media (max-width: 768px) {
  .section .heading-200 {
      font-size:3.75rem;
      margin-top: 2.625rem;
      margin-bottom: .975rem
  }
}

.section .heading-150 {
  font-size: 4.5rem;
  margin-top: 2.85rem;
  margin-bottom: 1.05rem
}

@media (max-width: 960px) {
  .section .heading-150 {
      font-size:3.5rem;
      margin-top: 2.55rem;
      margin-bottom: .95rem
  }
}

@media (max-width: 768px) {
  .section .heading-150 {
      font-size:3rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section .heading-130 {
  font-size: 3.9rem;
  margin-top: 2.67rem;
  margin-bottom: .99rem
}

@media (max-width: 960px) {
  .section .heading-130 {
      font-size:3.1rem;
      margin-top: 2.43rem;
      margin-bottom: .91rem
  }
}

@media (max-width: 768px) {
  .section .heading-130 {
      font-size:2.7rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section .heading-120 {
  font-size: 3.6rem;
  margin-top: 2.58rem;
  margin-bottom: .96rem
}

@media (max-width: 960px) {
  .section .heading-120 {
      font-size:2.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section .heading-120 {
      font-size:2.55rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section h1,.section .heading-100 {
  font-size: 3rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section h1,.section .heading-100 {
      font-size:2.5rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section h1,.section .heading-100 {
      font-size:2.25rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section h2,.section .heading-85 {
  font-size: 2.55rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section h2,.section .heading-85 {
      font-size:2.2rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section h2,.section .heading-85 {
      font-size:2.02rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section h3,.section .heading-70 {
  font-size: 2.1rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section h3,.section .heading-70 {
      font-size:1.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section h3,.section .heading-70 {
      font-size:1.8rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section h4 {
  font-size: 1.44rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section h4 {
      font-size:1.46rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section h4 {
      font-size:1.47rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-blackwhite {
  line-height: 1.5;
  font-size: 1.5rem;
  color: #000
}

.section-blackwhite .icon-image__inner {
  background-color: #000
}

.section-blackwhite .image-is-square .icon-image,.section-blackwhite .image-is-rounded .icon-image,.section-blackwhite .image-is-round .icon-image {
  background-color: #000
}

.section-blackwhite .image-is-square .icon-image__inner,.section-blackwhite .image-is-rounded .icon-image__inner,.section-blackwhite .image-is-round .icon-image__inner {
  background-color: #fff
}

@media (max-width: 960px) {
  .section-blackwhite {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .section-blackwhite {
      line-height:1.35;
      font-size: 1.37rem
  }
}

.section-blackwhite a {
  color: #000;
  text-decoration: underline
}

.section-blackwhite a:hover {
  color: #1a1a1a;
  text-decoration: underline
}

.section-blackwhite p,.section-blackwhite ol,.section-blackwhite ul {
  margin-top: 0;
  margin-bottom: .4em
}

.section-blackwhite span[style*='color'] a {
  color: inherit
}

.section-blackwhite button {
  line-height: 1.5
}

.section-blackwhite .text-small {
  font-size: 1.05rem
}

.section-blackwhite .accent-text-color {
  color: #000
}

.section-blackwhite .accent-background {
  color: #8c8c8c;
  background-color: #000
}

.section-blackwhite .accent-background a {
  color: #8c8c8c
}

.section-blackwhite .accent-border-link-color {
  border-color: #000
}

.section-blackwhite .accent-border-color {
  border-color: #000
}

@media (min-width: 960px) {
  .section-sidebar {
      line-height:1.35
  }
}

.section-white {
  line-height: 1.5;
  font-size: 1.5rem;
  color: #707070
}

.section-white .icon-image__inner {
  background-color: #eb8113
}

.section-white .image-is-square .icon-image,.section-white .image-is-rounded .icon-image,.section-white .image-is-round .icon-image {
  background-color: #eb8113
}

.section-white .image-is-square .icon-image__inner,.section-white .image-is-rounded .icon-image__inner,.section-white .image-is-round .icon-image__inner {
  background-color: white
}

@media (max-width: 960px) {
  .section-white {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .section-white {
      line-height:1.35;
      font-size: 1.37rem
  }
}

.section-white a {
  color: #eb8113;
  text-decoration: none
}

.section-white a:hover {
  color: #f09a41;
  text-decoration: underline
}

.section-white p,.section-white ol,.section-white ul {
  margin-top: 0;
  margin-bottom: .4em
}

.section-white span[style*='color'] a {
  color: inherit
}

.section-white button {
  line-height: 1.5
}

.section-white .text-small {
  font-size: 1.05rem
}

.section-white .accent-text-color {
  color: #eb8113
}

.section-white .accent-background {
  color: #fff;
  background-color: #eb8113
}

.section-white .accent-background a {
  color: #fff
}

.section-white .accent-border-link-color {
  border-color: #eb8113
}

.section-white .accent-border-color {
  border-color: #707070
}

.section-white h1,.section-white h2,.section-white h3,.section-white h4,.section-white h5,.section-white h6 {
  line-height: 1.1;
  color: #737373;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .section-white h1,.section-white h2,.section-white h3,.section-white h4,.section-white h5,.section-white h6 {
      line-height:1.1
  }
}

@media (max-width: 960px) {
  .section-white h1,.section-white h2,.section-white h3,.section-white h4,.section-white h5,.section-white h6 {
      line-height:1.1
  }
}

.section-white h1 a,.section-white h2 a,.section-white h3 a,.section-white h4 a,.section-white h5 a,.section-white h6 a {
  text-decoration: underline;
  color: #eb8113
}

.section-white h1 a:hover,.section-white h2 a:hover,.section-white h3 a:hover,.section-white h4 a:hover,.section-white h5 a:hover,.section-white h6 a:hover {
  color: #f09a41
}

.section-white .product-page .product__price__price {
  color: #737373
}

.section-white .heading-200 {
  font-size: 6rem;
  margin-top: 3.3rem;
  margin-bottom: 1.2rem
}

@media (max-width: 960px) {
  .section-white .heading-200 {
      font-size:4.5rem;
      margin-top: 2.85rem;
      margin-bottom: 1.05rem
  }
}

@media (max-width: 768px) {
  .section-white .heading-200 {
      font-size:3.75rem;
      margin-top: 2.625rem;
      margin-bottom: .975rem
  }
}

.section-white .heading-150 {
  font-size: 4.5rem;
  margin-top: 2.85rem;
  margin-bottom: 1.05rem
}

@media (max-width: 960px) {
  .section-white .heading-150 {
      font-size:3.5rem;
      margin-top: 2.55rem;
      margin-bottom: .95rem
  }
}

@media (max-width: 768px) {
  .section-white .heading-150 {
      font-size:3rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-white .heading-130 {
  font-size: 3.9rem;
  margin-top: 2.67rem;
  margin-bottom: .99rem
}

@media (max-width: 960px) {
  .section-white .heading-130 {
      font-size:3.1rem;
      margin-top: 2.43rem;
      margin-bottom: .91rem
  }
}

@media (max-width: 768px) {
  .section-white .heading-130 {
      font-size:2.7rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-white .heading-120 {
  font-size: 3.6rem;
  margin-top: 2.58rem;
  margin-bottom: .96rem
}

@media (max-width: 960px) {
  .section-white .heading-120 {
      font-size:2.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-white .heading-120 {
      font-size:2.55rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-white h1,.section-white .heading-100 {
  font-size: 3rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-white h1,.section-white .heading-100 {
      font-size:2.5rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-white h1,.section-white .heading-100 {
      font-size:2.25rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-white h2,.section-white .heading-85 {
  font-size: 2.55rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-white h2,.section-white .heading-85 {
      font-size:2.2rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-white h2,.section-white .heading-85 {
      font-size:2.02rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-white h3,.section-white .heading-70 {
  font-size: 2.1rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-white h3,.section-white .heading-70 {
      font-size:1.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-white h3,.section-white .heading-70 {
      font-size:1.8rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-white h4 {
  font-size: 1.44rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-white h4 {
      font-size:1.46rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-white h4 {
      font-size:1.47rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-content .element-form-is-error .element-form-input-text,.section-sidebar .element-form-is-error .element-form-input-text,.section-footer .element-form-is-error .element-form-input-text {
  border-color: #d9534f
}

.section-content .element-form-is-error .element-form-input-text:focus,.section-sidebar .element-form-is-error .element-form-input-text:focus,.section-footer .element-form-is-error .element-form-input-text:focus {
  box-shadow: 0 0 0 2px rgba(217,83,79,0.5),0 0 rgba(0,0,0,0)
}

.element-form-success {
  color: #5cb85c;
  border-color: #5cb85c
}

.element-form-error {
  color: #d9534f;
  border-color: #d9534f
}

.section-content .element-form-success {
  color: #3d8b3d;
  border-color: #3d8b3d
}

.section-content .element-form-error {
  color: #d9534f;
  border-color: #d9534f
}

.section-footer .element-form-success {
  color: #5cb85c;
  border-color: #5cb85c
}

.section-footer .element-form-error {
  color: #d9534f;
  border-color: #d9534f
}

.is-no-sidebar .sidebar-toggle {
  display: none
}

.header {
  float: left;
  position: relative
}

.header {
  line-height: 0
}

.header-title{
  line-height: 1;
  margin: 0;
  color: #b2b2b2;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal;
  font-size: 3.5rem;
  display: inline
}

.header-title{
  text-decoration: none
}

a.header-title:hover {
  color: rgba(178,178,178,0.8)
}

.slideshow--multiple>ul {
  list-style: none;
  margin: 0;
  padding: 0
}

.slideshow--multiple .slideshow-slide {
  display: none
}

.slideshow--multiple .slideshow-slide:first-child {
  display: block
}

.slideshow .bx-wrapper .slideshow-slide {
  display: block
}

.slideshow .bx-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;

  z-index: 0
}

.slideshow .bx-wrapper img {
  max-width: 100%;
  display: block
}

.slideshow .bx-viewport ul {
  padding: 0;
  margin: 0
}

.slideshow .bx-viewport li {
  list-style: none
}

.slideshow .bx-controls-direction {
  position: absolute;
  top: 50%;
  width: 100%
}

.slideshow .bx-prev,.slideshow .bx-next {
  position: absolute;
  font-weight: bold;
  font-family: 'Comic Sans MS','TSCu_Comic',cursive;
  line-height: 3rem;
  margin-top: -1.5rem;
  width: 3rem;
  height: 3rem;
  outline: 0;
  text-align: center;
  text-decoration: none;
  z-index: 55;
  background: rgba(15,15,15,0.5);
  color: #fff;
  transition: opacity 150ms ease;
  font-size: 1.6rem;
  border-radius: 2px
}

.slideshow .bx-prev:hover,.slideshow .bx-next:hover {
  opacity: .8;
  color: #fff;
  text-decoration: none
}

.slideshow .bx-prev.disabled,.slideshow .bx-next.disabled {
  display: none
}

.slideshow .bx-prev {
  left: 1rem
}

.slideshow .bx-prev>i::before {
  margin: 0 0 0 -0.1em
}

.slideshow .bx-next {
  right: 1rem
}

.slideshow .bx-next>i::before {
  margin: 0 -0.1em 0 0
}

@media screen and (max-width: 750px) {
  .slideshow .bx-prev {
      left:.5rem
  }

  .slideshow .bx-next {
      right: .5rem
  }
}

.slideshow .bx-caption {
  color: white;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  font-family: "Source Sans Pro","sans-serif";
  text-align: center;
  width: 100%;
  padding: 0 10px
}

@media screen and (max-width: 750px) {
  .slideshow .bx-caption {
      padding:0 5px
  }
}

@media screen and (max-width: 500px) {
  .slideshow .bx-caption {
      padding:0 1px
  }
}

.slideshow-slide-content--no-backdrop .bx-caption {
  text-shadow: 0 1px 3px rgba(0,0,0,0.45)
}

.slideshow .bx-caption-wrapper {
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%
}

.slideshow {
  position: relative;
  margin: 0
}

.slideshow-slide-content {
  overflow: hidden;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center
}

.slideshow-slide-content-height-enforcer-wrapper {
  display: block;
  width: 100%;
  margin-left: -100%;
  flex-shrink: 0
}

.slideshow-slide-content-height-enforcer {
  width: 1px;
  margin-left: -1px
}

.slideshow-slide-content.slideshow-slide-content--display-cover {
  background-size: cover
}

.slideshow-slide-content.slideshow-slide-content--display-contain {
  background-size: contain
}

.slideshow-sub-title,.slideshow-btn.btn-default {
  font-size: 3rem;
  line-height: 1.2em
}

@media screen and (max-width: 750px) {
  .slideshow-sub-title,.slideshow-btn.btn-default {
      font-size:2.1rem
  }
}

@media screen and (max-width: 500px) {
  .slideshow-sub-title,.slideshow-btn.btn-default {
      font-size:1.5rem
  }
}

.slideshow-title {
  font-size: 6rem;
  line-height: 120% !important
}

@media screen and (max-width: 750px) {
  .slideshow-title {
      font-size:4.2rem
  }
}

@media screen and (max-width: 500px) {
  .slideshow-title {
      font-size:3rem
  }
}

.banner-lg {
  font-size: 120%
}

.banner-lg .slideshow-title {
  font-size: 7.2rem;
  line-height: 120% !important
}

@media screen and (max-width: 750px) {
  .banner-lg .slideshow-title {
      font-size:5.04rem
  }
}

@media screen and (max-width: 500px) {
  .banner-lg .slideshow-title {
      font-size:3.6rem
  }
}

.banner-sm {
  font-size: 85%
}

.banner-sm .slideshow-title {
  font-size: 5.1rem;
  line-height: 120% !important
}

@media screen and (max-width: 750px) {
  .banner-sm .slideshow-title {
      font-size:3.57rem
  }
}

@media screen and (max-width: 500px) {
  .banner-sm .slideshow-title {
      font-size:2.55rem
  }
}

.slideshow-sub-title,.slideshow-btn.btn-default {
  font-size: 1.4em
}

@media screen and (max-width: 750px) {
  .slideshow-sub-title,.slideshow-btn.btn-default {
      font-size:1.3em
  }
}

@media screen and (max-width: 500px) {
  .slideshow-sub-title,.slideshow-btn.btn-default {
      font-size:1.2em
  }
}

.slideshow .bx-caption .slideshow-btn,.slideshow .bx-caption .slideshow-sub-title {
  display: inline-block;
  margin: 10px 0;
  transition: margin 300ms
}

.slideshow .slideshow-btn {
  text-shadow: none
}

.slideshow .info-label {
  font-weight: normal;
  font-style: none;
  text-shadow: none;
  left: 50%
}

.slideshow.banner-sm .bx-caption .slideshow-btn,.slideshow.banner-sm .slideshow-sub-title {
  margin: 5px 0 0 0
}

.slideshow.banner-md .bx-caption .slideshow-btn,.slideshow.banner-md .slideshow-sub-title {
  margin: 25px 0 0 0
}

.slideshow.banner-lg .bx-caption .slideshow-btn,.slideshow.banner-lg .slideshow-sub-title {
  margin: 30px 0 0 0
}

.slideshow__scroll-arrow {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 1rem;
  margin: auto;
  width: 5.6rem;
  height: 5.2rem;
  text-align: center;
  line-height: 4rem;
  font-size: 4rem;
  color: white;
  text-shadow: 0 2px 2px rgba(0,0,0,0.4);
  cursor: pointer
}

.banner-lg.slideshow--height-ratio .slideshow__scroll-arrow {
  visibility: visible
}

.slideshow__scroll-arrow>i {
  display: block;
  line-height: inherit;
  transition: transform 150ms ease
}

.slideshow__scroll-arrow:hover>i {
  transform: translate(0, .5rem)
}

.slideshow--multiple .bx-caption {
  padding-right: 4rem;
  padding-left: 4rem
}

@media screen and (max-width: 600px) {
  .slideshow--multiple .bx-caption {
      padding-right:1px;
      padding-left: 1px
  }
}

@media screen and (max-width: 600px) {
  .slideshow .bx-controls {
      display:none
  }
}

.is-no-slideshow .slideshow-toggle {
  display: none
}

.slideshow .bx-caption {
  display: none;
  padding: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 10;
  box-sizing: border-box
}

@media (max-width: 960px) {
  .slideshow .bx-caption {
      padding:25px
  }
}

@media (max-width: 720px) {
  .slideshow .bx-caption {
      padding:5px
  }
}

.slideshow .bx-caption .mce-content-body {
  cursor: default
}

.slideshow .bx-caption .mce-edit-focus {
  cursor: text
}

.slideshow .bx-caption .bx-edit {
  display: inline-block
}

.slideshow-slide-content--captions .bx-caption {
  display: block
}

@media screen and (min-width: 1200px) {
  .slideshow--full-width .slideshow-slide-content-height-enforcer {
      padding-bottom:0 !important
  }
}

.tree-container:not(.tree-container__empty)+.credits {
  margin-top: 1em
}

.credits-owner {
  margin: 0;
  float: left;
  position: relative
}

.credits-right {
  float: right;
  position: relative
}

.footer-text-content {
  min-width: 1.4rem
}

@media (max-width: 500px) {
  .credits-owner,.credits-right {
      float:left;
      clear: both
  }
}

body {
  background-color: #333333
}

.background {
  width: 100%;
  height: 100%;
  background-color: #333333;
  background-size: auto;
  background-repeat: repeat;
  background-position: top left;
  position: absolute;
  opacity: 1;
  background-image: none
}

.body {
  min-height: 100%;

  height: 100%;
  position: relative
}

.body:before,.body:after {
  display: table;
  content: '';
  line-height: 0
}

.body:after {
  clear: both
}

@media (max-width: 960px) {
  .message-bar {
      order:-1
  }
}

.message-bar--accent {
  background: #eb8113;
  color: #fff
}

.message-bar-usps {
  min-height: 2.5em
}

.tree-empty-placeholder {
  color: rgba(255,255,255,0.8);
  border: 1px dashed rgba(255,255,255,0.4)
}

.tree-empty-placeholder__image {
  background-image: url('https://assets.jwwb.nl/assets/img/content-empty-light.png?version=1')
}

.section-content .tree-empty-placeholder {
  color: rgba(0,0,0,0.6);
  border: 1px dashed rgba(0,0,0,0.3)
}

.section-content .tree-empty-placeholder__image {
  background-image: url('https://assets.jwwb.nl/assets/img/content-empty.png?version=1')
}

.section-sidebar .tree-empty-placeholder {
  color: rgba(0,0,0,0.6);
  border: 1px dashed rgba(0,0,0,0.3)
}

.section-sidebar .tree-empty-placeholder__image {
  background-image: url('https://assets.jwwb.nl/assets/img/content-empty.png?version=1')
}

.section-footer .tree-empty-placeholder {
  color: rgba(255,255,255,0.8);
  border: 1px dashed rgba(255,255,255,0.4)
}

.section-footer .tree-empty-placeholder__image {
  background-image: url('https://assets.jwwb.nl/assets/img/content-empty-light.png?version=1')
}

.popover-container--inline {
  display: inline
}

.popover-container--modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center
}

.popover-backdrop {
  position: fixed;
  z-index: 1049;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.2);
  transition: visibility 200ms, opacity 200ms ease
}

.is-hidden .popover-backdrop {
  visibility: hidden;
  opacity: 0
}

.popover {
  position: relative;
  z-index: 1050;
  text-align: left;
  text-transform: none;
  will-change: transform;
  transition: visibility 200ms,opacity 200ms ease,transform 200ms ease-out
}

.popover-container--inline .popover {
  position: absolute;
  bottom: -6px;
  width: 100%;
  max-width: 100px
}

.popover-container--modal .popover {
  width: 100%;
  padding: 20px
}

.is-hidden .popover {
  visibility: hidden;
  opacity: 0;
  transform: scale3d(.9, .9, 1);
  transition: visibility 200ms,opacity 200ms ease,transform 200ms ease-in
}

.popover:focus {
  outline: none
}

.popover__arrow {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent white transparent
}

.popover__content {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
  background: white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2)
}

.popover-container--modal .popover__content {
  padding: 15px
}

.popover-container--inline .popover__content {
  position: absolute;
  width: 25em
}

.popover-container--inline .popover__content--align-right {
  right: 0
}

.menu-is-desktop .menu-horizontal .popover-container--inline .popover__content {
  right: 0
}

.menu-is-desktop .menu-vertical .popover-container--inline .popover__content {
  left: 0
}

.menu-is-desktop .menu-item--left .popover-container--inline .popover__content {
  right: auto
}

.search {
  display: flex
}

.search__input {
  flex: 1;
  padding: 10px 12px;
  min-width: 0;
  font-size: inherit;
  border-radius: 3px 0 0 3px;
  transition: border-color 150ms ease
}

.search__input:focus {
  outline: none
}

.search__submit {
  padding: 0 15px;
  font-size: inherit;
  border: 0;
  border-radius: 0 3px 3px 0;
  transition: background 150ms ease
}

.search__submit:active {
  outline: none
}

.popover .search__input {
  color: #222;
  background: white;
  border: 1px solid #ccc;
  border-right: 0
}

.popover .search__input:focus {
  border-color: #eb8113
}

.popover .search__submit {
  color: #fff;
  background: #eb8113
}

.popover .search__submit:focus,.popover .search__submit:hover {
  background: #cf7111
}

.popover .search__submit:active {
  background: #c16a10
}

.section-content .search__input {
  color: #222;
  background: white;
  border: 1px solid #c6c6c6;
  border-right: 0
}

.section-content .search__input:focus {
  border-color: #eb8113
}

.section-content .search__submit {
  color: #fff;
  background: #eb8113
}

.section-content .search__submit:focus,.section-content .search__submit:hover {
  background: #cf7111
}

.section-content .search__submit:active {
  background: #c16a10
}

.icon-burger {
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.icon-burger,.icon-burger::before,.icon-burger::after {
  display: block;
  width: 18px;
  height: 3px;
  background-color: currentColor;
  border-radius: 0;
  transform-origin: center center;
  transition-property: background-color,transform,width;
  transition-duration: 200ms
}

.icon-burger::before,.icon-burger::after {
  content: '';
  position: absolute
}

.icon-burger::before {
  top: -7px
}

.icon-burger::after {
  top: 7px
}

.icon-burger--cross {
  background-color: transparent;
  transform: rotate(180deg)
}

.icon-burger--cross::before,.icon-burger--cross::after {
  width: 24px
}

.icon-burger--cross::before {
  transform: translate(-3px, 7px) rotate(-45deg)
}

.icon-burger--cross::after {
  transform: translate(-3px, -7px) rotate(45deg)
}

.icon-search {
  display: block;
  width: 20px;
  height: 20px
}

.icon-search::before,.icon-search::after {
  content: '';
  display: block;
  position: absolute
}

.icon-search::before {
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  box-shadow: inset 0 0 0 3px currentColor
}

.icon-search::after {
  width: 9px;
  height: 3px;
  background-color: currentColor;
  border-radius: 0 0 0 0;
  transform-origin: -5.5px 1.5px;
  transform: translate(14px, 7px) rotate(45deg)
}

.cc-window.cc-theme-jw {
  font-family: "Source Sans Pro","sans-serif";
  line-height: 1.5;
  font-size: 1.5rem
}

@media (max-width: 960px) {
  .cc-window.cc-theme-jw {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .cc-window.cc-theme-jw {
      line-height:1.35;
      font-size: 1.37rem
  }
}

.cc-window.cc-theme-jw .cc-allow.cc-btn,.cc-window.cc-theme-jw .cc-dismiss.cc-btn {
  background: #eb8113;
  color: #fff;
  border-color: black
}

.cc-window.cc-theme-jw .cc-allow.cc-btn:hover,.cc-window.cc-theme-jw .cc-dismiss.cc-btn:hover {
  background: #f09a41;
  color: #fff
}

.section .element-ads h3 {
  font-size: 25px
}

@media (max-width: 960px) {
  .section .element-ads h3 {
      font-size:20px
  }
}

.section .element-ads--accent {
  background: #eb8113
}

.section .element-ads--accent h3 {
  color: #fff
}

.section .element-ads--tint {
  background: #e0e0e0
}

.section .element-ads--tint h3 {
  color: #656565
}

.btn-rounded {
  border-radius: .2em
}

.btn-round {
  border-radius: 1000px;
  padding-left: 1.1em;
  padding-right: 1.1em
}

.btn__default-color {
  outline-color: #eb8113
}

.btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.btn__default-color.btn-style-flat:hover:not(:disabled),.btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.btn__default-color.btn-style-border:hover:not(:disabled),.btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.btn__default-color.btn-style-default:hover:not(:disabled),.btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.btn--default-color {
  color: #eb8113
}

.section-white .btn__default-color {
  outline-color: #eb8113
}

.section-white .btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.section-white .btn__default-color.btn-style-flat:hover:not(:disabled),.section-white .btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.section-white .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.section-white .btn__default-color.btn-style-border:hover:not(:disabled),.section-white .btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.section-white .btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.section-white .btn__default-color.btn-style-default:hover:not(:disabled),.section-white .btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.section-white .btn--default-color {
  color: #eb8113
}

.slideshow .btn__default-color {
  outline-color: white
}

.slideshow .btn__default-color.btn-style-flat {
  background: white;
  color: #737373
}

.slideshow .btn__default-color.btn-style-flat:hover:not(:disabled),.slideshow .btn__default-color.btn-style-flat.btn__active {
  background: #e6e6e6;
  color: #737373
}

.slideshow .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid white;
  color: white
}

.slideshow .btn__default-color.btn-style-border:hover:not(:disabled),.slideshow .btn__default-color.btn-style-border.btn__active {
  color: #737373;
  background: white
}

.slideshow .btn__default-color.btn-style-default {
  background: transparent;
  border: 2px solid white;
  color: white
}

.slideshow .btn__default-color.btn-style-default:hover:not(:disabled),.slideshow .btn__default-color.btn-style-default.btn__active {
  color: #737373;
  background: white
}

.slideshow .btn--default-color {
  color: white
}

.section-content .product__price s,.section-content .product-gallery__price s {
  text-decoration-color: #d9534f
}

.section-sidebar .product__price s,.section-sidebar .product-gallery__price s {
  text-decoration-color: #d9534f
}

.section-footer .product__price s,.section-footer .product-gallery__price s {
  text-decoration-color: #d9534f
}

.product,.product__description,.product-page__description,.product-page__social {
  border-color: rgba(112,112,112,0.2)
}

.columns .product .product,.image-gallery__thumbnail-item {
  border-radius: 0
}

.product__free-shipping-motivator {
  color: #eb8113
}

.select {
  display: inline-flex;
  line-height: 1.5
}

.select__prefix {
  position: relative;
  margin-right: -1px
}

.select__input {
  height: 1.5em;
  box-sizing: content-box;
  flex: 1 1 auto;
  min-width: 0
}

.select--size-small .select__prefix,.select--size-small .select__input {
  font-size: 1em;
  padding-top: .3em;
  padding-bottom: .3em;
  padding-left: .6em
}

.select--size-default .select__prefix,.select--size-default .select__input {
  font-size: 1.3em;
  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: .6em
}

.select--size-large .select__prefix,.select--size-large .select__input {
  font-size: 1.5em;
  padding-top: .39em;
  padding-bottom: .39em;
  padding-left: .6em
}

.section .cart-item {
  border-color: rgba(112,112,112,0.16)
}

.section .cart-totals__row {
  border-color: rgba(112,112,112,0.16)
}

.section .cart-totals__row--free_shipping_motivator,.section .cart-totals__row--free_shipping .cart-totals__row-price {
  color: #eb8113
}

.section .product-quantity-input__remove {
  color: rgba(112,112,112,0.6)
}

.section .product-quantity-input__remove:hover {
  color: #707070
}

.section .cart-totals__tooltip {
  display: flex;
  color: rgba(112,112,112,0.6);
  font-size: .9em
}

.section .cart-totals__tooltip .website-rendering-icon-help-circled {
  margin-right: .25em
}

.section-white .cart-item {
  border-color: rgba(112,112,112,0.16)
}

.section-white .cart-totals__row {
  border-color: rgba(112,112,112,0.16)
}

.section-white .cart-totals__row--free_shipping_motivator,.section-white .cart-totals__row--free_shipping .cart-totals__row-price {
  color: #eb8113
}

.section-white .product-quantity-input__remove {
  color: rgba(112,112,112,0.6)
}

.section-white .product-quantity-input__remove:hover {
  color: #707070
}

.section-white .cart-totals__tooltip {
  display: flex;
  color: rgba(112,112,112,0.6);
  font-size: .9em
}

.section-white .cart-totals__tooltip .website-rendering-icon-help-circled {
  margin-right: .25em
}


.map--marker-accent .marker__back {
  fill: #eb8113
}

.map--marker-accent .marker__circle {
  fill: #fff
}

.element-separator,.news-post,.comment,.columns__node--separator-left,.columns__node--separator-right,.website-logout,.element-accordion__heading {
  border-color: rgba(112,112,112,0.2)
}

.steps__step {
  background-color: #f9f9f9;
  border-color: rgba(112,112,112,0.3);
  color: #8b8b8b
}

a.steps__step:hover,a.steps__step:focus {
  color: #8b8b8b
}

.steps__step--enabled {
  color: #707070
}

a.steps__step--enabled:hover,a.steps__step--enabled:focus {
  color: #707070
}

.steps__step--completed {
  background-color: #f8ede2;
  border-color: #eb8113;
  color: #a45a0d
}

a.steps__step--completed:hover,a.steps__step--completed:focus {
  color: #a45a0d
}

.steps__step--selected {
  background-color: #eb8113;
  border-color: #eb8113;
  color: #fff
}

a.steps__step--selected:hover,a.steps__step--selected:focus {
  color: #fff
}

.toggle-block__label {
  border-color: rgba(112,112,112,0.2)
}

input:checked+.toggle-block__label {
  border-color: #eb8113;
  box-shadow: 0 0 0 1px #eb8113
}

.toggle-block__label.element-form-is-error,input:checked+.toggle-block__label.element-form-is-error {
  border-color: #d9534f
}

.element-form-well {
  background: #eee
}

.element-form-input-text {
  border-color: rgba(160,160,160,0.5);
  color: #707070;
  background-color: #fff;
  box-shadow: 0 0 rgba(0,0,0,0)
}

.element-form-input-text:hover {
  border-color: #a0a0a0
}

.element-form-input-text:focus {
  border-color: #eb8113;
  box-shadow: 0 0 0 2px #f2bd86,0 0 rgba(0,0,0,0)
}


input[type='radio']:focus,input[type='checkbox']:focus {
  box-shadow: 0 0 0 2px #eb8113;
  outline: none
}

input[type='radio']:focus:not(:focus-visible),input[type='checkbox']:focus:not(:focus-visible) {
  box-shadow: none
}

.pagination__page {
  color: #eb8113
}

.pagination__page:hover {
  background: rgba(235,129,19,0.15);
  color: #eb8113
}

.pagination__page--active,.pagination__page--active:hover {
  box-shadow: inset 0 0 0 2px #eb8113;
  background: transparent;
  color: #eb8113
}

.product-sticker {
  color: #fff;
  background: #eb8113
}

.text--muted {
  color: #a7a7a7
}

.element-social-share .share-button--wrapper {
  border-color: rgba(112,112,112,0.2)
}

.element-rating--color-accent .element-rating__icon-full {
  color: #eb8113
}

.element-rating__icon-empty {
  color: #d0d0d0
}

.element-audio {
  --plyr-color-main: #eb8113;
  --plyr-audio-controls-background: #eaeaea;
  --plyr-audio-control-color: #707070;
  --plyr-audio-control-background-hover: #eb8113;
  --plyr-audio-control-color-hover: #fff;
  --plyr-audio-progress-buffered-background: rgba(112,112,112,0.3);
  --plyr-control-radius: 0
}

.element-audio .plyr--audio .plyr__controls {
  border-radius: 0;
  border-color: #d2d2d2
}

.element-video {
  --plyr-color-main: #eb8113;
  --plyr-video-control-background-hover: #eb8113;
  --plyr-video-control-color-hover: #fff
}

.element-social-follow--color-default.element-social-follow--style-border .element-social-follow-profile,.element-social-follow--color-default.element-social-follow--style-icononly .element-social-follow-profile {
  color: #eb8113;
  border-color: #eb8113
}

.element-social-follow--color-default.element-social-follow--style-solid .element-social-follow-profile {
  background-color: #eb8113;
  color: #fff
}

.element-social-follow__default-color-getter {
  color: #eb8113
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-facebook {
  background-color: #3b5998
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-facebook,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-facebook {
  color: #3b5998;
  border-color: #3b5998
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-twitter {
  background-color: #000
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-twitter,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-twitter {
  color: #000;
  border-color: #000
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-pinterest {
  background-color: #e60023
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-pinterest,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-pinterest {
  color: #e60023;
  border-color: #e60023
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-instagram {
  background-color: #e1306c
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-instagram,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-instagram {
  color: #e1306c;
  border-color: #e1306c
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-youtube {
  background-color: #ff0000
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-youtube,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-youtube {
  color: #ff0000;
  border-color: #ff0000
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tumblr {
  background-color: #35465c
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tumblr,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tumblr {
  color: #35465c;
  border-color: #35465c
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-linkedin {
  background-color: #0077b5
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-linkedin,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-linkedin {
  color: #0077b5;
  border-color: #0077b5
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tiktok {
  background-color: #010101
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tiktok,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tiktok {
  color: #010101;
  border-color: #010101
}

.element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-whatsapp {
  background-color: #25d366
}

.element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-whatsapp,.element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-whatsapp {
  color: #23ca62;
  border-color: #23ca62
}

.image-gallery__main:has(.image-gallery__slides :focus-visible) {
  outline: 1px dotted #eb8113;
  outline-offset: 1px
}

.image-gallery__thumbnail-item {
  border-color: rgba(112,112,112,0.3)
}

.image-gallery__thumbnail-item:hover {
  border-color: rgba(112,112,112,0.6)
}

.image-gallery__thumbnail-item.image-gallery__thumbnail-item--active {
  border-color: #eb8113
}

.element-accordion--style-box .element-accordion__heading {
  background-color: #28c80c;
  color: #fff;
  border-color: #bc670f
}

.element-accordion--style-box .element-accordion__item {
  background-color: rgba(112,112,112,0.05)
}

.element-accordion--style-box .element-accordion__content {
  border-color: #bc670f
}

.table.table--header.table--border th,.table.table--header.table--striped th {
  background-color: #eb8113;
  color: #fff
}

.table.table--border tr,.table.table--striped tr td {
  border-top: 1px solid rgba(112,112,112,0.1)
}

.table.table--striped tr:nth-child(odd) td {
  background-color: rgba(112,112,112,0.05)
}

.table.table--hover tr:hover td {
  background-color: rgba(112,112,112,0.15)
}

.table.table--nowrap {
  white-space: nowrap
}

.responsive-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(112,112,112,0.3)
}

.handsontable .table--header.table--border tr:first-child td,.handsontable .table--header.table--striped tr:first-child td {
  background-color: #eb8113;
  color: #fff
}

.product,.product__description,.product-page__description,.product-page__social {
  border-color: rgba(112,112,112,0.2)
}

.columns .product .product,.image-gallery__thumbnail-item {
  border-radius: 0
}

.product__free-shipping-motivator {
  color: #eb8113
}

.product-gallery--empty {
  border: 1px solid rgba(112,112,112,0.3);
  color: rgba(112,112,112,0.5)
}

.product-gallery__content {
  background: #f9f9f9
}

.product-gallery--style-bordered .product-gallery__content {
  border-color: rgba(112,112,112,0.3)
}

.product-gallery--style-bordered .product-gallery__content:hover,.product-gallery--style-clean .product-gallery__content:hover {
  background: #fff;
  border-color: rgba(112,112,112,0.6)
}

.product-gallery--style-bordered .product-gallery__content:focus-within,.product-gallery--style-clean .product-gallery__content:focus-within {
  background: #fff;
  border-color: rgba(112,112,112,0.6)
}

.product-gallery--style-image-only .product-gallery__info-container {
  background-color: rgba(255,255,255,0.9)
}

.product-gallery__price {
  color: #999
}

.product-gallery--style-bordered .product-gallery__expand-container button,.product-gallery--style-clean .product-gallery__expand-container button,.product-gallery--style-bordered .product-gallery__expand-container select,.product-gallery--style-clean .product-gallery__expand-container select {
  height: 32px;
  box-sizing: border-box
}

.product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover,.product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover,.product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover,.product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -47px
}

.product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within,.product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within,.product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within,.product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -47px
}

.product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-button .product-gallery__expand-container,.product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-options .product-gallery__expand-container {
  height: 32px;
  margin-top: -32px
}

.product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover,.product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -89px
}

.product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within,.product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -89px
}

.product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container,.product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container {
  height: 74px;
  margin-top: -74px
}

.is-touch-device .product-gallery--style-bordered .product-gallery__item .product-gallery__content:hover,.is-touch-device .product-gallery--style-clean .product-gallery__item .product-gallery__content:hover {
  margin-bottom: 0
}

.is-touch-device .product-gallery--style-bordered .product-gallery__item .product-gallery__content:focus-within,.is-touch-device .product-gallery--style-clean .product-gallery__item .product-gallery__content:focus-within {
  margin-bottom: 0
}

.is-touch-device .product-gallery--style-bordered .product-gallery__item .product-gallery__expand-container,.is-touch-device .product-gallery--style-clean .product-gallery__item .product-gallery__expand-container {
  height: auto;
  margin-top: 15px;
  opacity: 1;
  visibility: visible
}

.product-gallery-filter__button {
  color: #eb8113
}

.btn__default-color {
  outline-color: #eb8113
}

.btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.btn__default-color.btn-style-flat:hover:not(:disabled),.btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.btn__default-color.btn-style-border:hover:not(:disabled),.btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.btn__default-color.btn-style-default:hover:not(:disabled),.btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.btn--default-color {
  color: #eb8113
}

.section-footer {
  line-height: 1.5;
  font-size: 1.4rem;
  color: #b1b0ac
}

.section-footer .element-separator,.section-footer .news-post,.section-footer .comment,.section-footer .columns__node--separator-left,.section-footer .columns__node--separator-right,.section-footer .website-logout,.section-footer .element-accordion__heading {
  border-color: rgba(177,176,172,0.2)
}

.section-footer .steps__step {
  background-color: #333333;
  border-color: rgba(177,176,172,0.3);
  color: #989794
}

a.section-footer .steps__step:hover,a.section-footer .steps__step:focus {
  color: #989794
}

.section-footer .steps__step--enabled {
  color: #b1b0ac
}

a.section-footer .steps__step--enabled:hover,a.section-footer .steps__step--enabled:focus {
  color: #b1b0ac
}

.section-footer .steps__step--completed {
  background-color: #453b30;
  border-color: #eb8113;
  color: #f2a759
}

a.section-footer .steps__step--completed:hover,a.section-footer .steps__step--completed:focus {
  color: #f2a759
}

.section-footer .steps__step--selected {
  background-color: #eb8113;
  border-color: #eb8113;
  color: #fff
}

a.section-footer .steps__step--selected:hover,a.section-footer .steps__step--selected:focus {
  color: #fff
}

.section-footer .toggle-block__label {
  border-color: rgba(177,176,172,0.2)
}

input:checked+.section-footer .toggle-block__label {
  border-color: #eb8113;
  box-shadow: 0 0 0 1px #eb8113
}

.section-footer .toggle-block__label.element-form-is-error,input:checked+.section-footer .toggle-block__label.element-form-is-error {
  border-color: #d9534f
}

.section-footer .element-form-well {
  background: #3d3d3d
}

.section-footer .element-form-input-text {
  border-color: rgba(140,140,140,0.5);
  color: #b1b0ac;
  background-color: #4d4d4d;
  box-shadow: 0 0 rgba(0,0,0,0)
}

.section-footer .element-form-input-text:hover {
  border-color: #8c8c8c
}

.section-footer .element-form-input-text:focus {
  border-color: #eb8113;
  box-shadow: 0 0 0 2px #8f5a23,0 0 rgba(0,0,0,0)
}

.section-footer input[type='radio']:focus,.section-footer input[type='checkbox']:focus {
  box-shadow: 0 0 0 2px #eb8113;
  outline: none
}

.section-footer input[type='radio']:focus:not(:focus-visible),.section-footer input[type='checkbox']:focus:not(:focus-visible) {
  box-shadow: none
}

.section-footer .pagination__page {
  color: #eb8113
}

.section-footer .pagination__page:hover {
  background: rgba(235,129,19,0.15);
  color: #eb8113
}

.section-footer .pagination__page--active,.section-footer .pagination__page--active:hover {
  box-shadow: inset 0 0 0 2px #eb8113;
  background: transparent;
  color: #eb8113
}

.section-footer .product-sticker {
  color: #fff;
  background: #eb8113
}

.section-footer .text--muted {
  color: #7f7e7c
}

.section-footer .element-social-share .share-button--wrapper {
  border-color: rgba(177,176,172,0.2)
}

.section-footer .element-rating--color-accent .element-rating__icon-full {
  color: #eb8113
}

.section-footer .element-rating__icon-empty {
  color: #595957
}

.section-footer .element-audio {
  --plyr-color-main: #eb8113;
  --plyr-audio-controls-background: #595959;
  --plyr-audio-control-color: #b1b0ac;
  --plyr-audio-control-background-hover: #eb8113;
  --plyr-audio-control-color-hover: #fff;
  --plyr-audio-progress-buffered-background: rgba(177,176,172,0.3);
  --plyr-control-radius: 0
}

.section-footer .element-audio .plyr--audio .plyr__controls {
  border-radius: 0;
  border-color: #6b6a6a
}

.section-footer .element-video {
  --plyr-color-main: #eb8113;
  --plyr-video-control-background-hover: #eb8113;
  --plyr-video-control-color-hover: #fff
}

.section-footer .element-social-follow--color-default.element-social-follow--style-border .element-social-follow-profile,.section-footer .element-social-follow--color-default.element-social-follow--style-icononly .element-social-follow-profile {
  color: #eb8113;
  border-color: #eb8113
}

.section-footer .element-social-follow--color-default.element-social-follow--style-solid .element-social-follow-profile {
  background-color: #eb8113;
  color: #fff
}

.section-footer .element-social-follow__default-color-getter {
  color: #eb8113
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-facebook {
  background-color: #3b5998
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-facebook,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-facebook {
  color: #3f5fa3;
  border-color: #3f5fa3
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-twitter {
  background-color: #000
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-twitter,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-twitter {
  color: #fff;
  border-color: #fff
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-pinterest {
  background-color: #e60023
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-pinterest,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-pinterest {
  color: #e60023;
  border-color: #e60023
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-instagram {
  background-color: #e1306c
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-instagram,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-instagram {
  color: #e1306c;
  border-color: #e1306c
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-youtube {
  background-color: #ff0000
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-youtube,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-youtube {
  color: #ff0000;
  border-color: #ff0000
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tumblr {
  background-color: #35465c
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tumblr,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tumblr {
  color: #4b6483;
  border-color: #4b6483
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-linkedin {
  background-color: #0077b5
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-linkedin,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-linkedin {
  color: #0077b5;
  border-color: #0077b5
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tiktok {
  background-color: #010101
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tiktok,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tiktok {
  color: #fff;
  border-color: #fff
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-whatsapp {
  background-color: #25d366
}

.section-footer .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-whatsapp,.section-footer .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-whatsapp {
  color: #25d366;
  border-color: #25d366
}

.section-footer .image-gallery__main:has(.image-gallery__slides :focus-visible) {
  outline: 1px dotted #eb8113;
  outline-offset: 1px
}

.section-footer .image-gallery__thumbnail-item {
  border-color: rgba(177,176,172,0.3)
}

.section-footer .image-gallery__thumbnail-item:hover {
  border-color: rgba(177,176,172,0.6)
}

.section-footer .image-gallery__thumbnail-item.image-gallery__thumbnail-item--active {
  border-color: #eb8113
}

.section-footer .element-accordion--style-box .section-footer .element-accordion__heading {
  background-color: #eb8113;
  color: #fff;
  border-color: #bc670f
}

.section-footer .element-accordion--style-box .section-footer .element-accordion__item {
  background-color: rgba(177,176,172,0.05)
}

.section-footer .element-accordion--style-box .section-footer .element-accordion__content {
  border-color: #bc670f
}

.section-footer .table.table--header.table--border th,.section-footer .table.table--header.table--striped th {
  background-color: #eb8113;
  color: #fff
}

.section-footer .table.table--border tr,.section-footer .table.table--striped tr td {
  border-top: 1px solid rgba(177,176,172,0.1)
}

.section-footer .table.table--striped tr:nth-child(odd) td {
  background-color: rgba(177,176,172,0.05)
}

.section-footer .table.table--hover tr:hover td {
  background-color: rgba(177,176,172,0.15)
}

.section-footer .table.table--nowrap {
  white-space: nowrap
}

.section-footer .responsive-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(177,176,172,0.3)
}

.section-footer .handsontable .table--header.table--border tr:first-child td,.section-footer .handsontable .table--header.table--striped tr:first-child td {
  background-color: #eb8113;
  color: #fff
}

.section-footer .product,.section-footer .product__description,.section-footer .product-page__description,.section-footer .product-page__social {
  border-color: rgba(177,176,172,0.2)
}

.section-footer .columns .product .product,.section-footer .image-gallery__thumbnail-item {
  border-radius: 0
}

.section-footer .product__free-shipping-motivator {
  color: #eb8113
}

.section-footer .product-gallery--empty {
  border: 1px solid rgba(177,176,172,0.3);
  color: rgba(177,176,172,0.5)
}

.section-footer .product-gallery__content {
  background: #333333
}

.section-footer .product-gallery--style-bordered .product-gallery__content {
  border-color: rgba(177,176,172,0.3)
}

.section-footer .product-gallery--style-bordered .product-gallery__content:hover,.section-footer .product-gallery--style-clean .product-gallery__content:hover {
  background: #424242;
  border-color: rgba(177,176,172,0.6)
}

.section-footer .product-gallery--style-bordered .product-gallery__content:focus-within,.section-footer .product-gallery--style-clean .product-gallery__content:focus-within {
  background: #424242;
  border-color: rgba(177,176,172,0.6)
}

.section-footer .product-gallery--style-image-only .product-gallery__info-container {
  background-color: rgba(66,66,66,0.9)
}

.section-footer .product-gallery__price {
  color: #8b8b88
}

.section-footer .product-gallery--style-bordered .product-gallery__expand-container button,.section-footer .product-gallery--style-clean .product-gallery__expand-container button,.section-footer .product-gallery--style-bordered .product-gallery__expand-container select,.section-footer .product-gallery--style-clean .product-gallery__expand-container select {
  height: 30px;
  box-sizing: border-box
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover,.section-footer .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover,.section-footer .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover,.section-footer .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -45px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.section-footer .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within,.section-footer .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within,.section-footer .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within,.section-footer .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -45px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.section-footer .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__expand-container,.section-footer .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__expand-container {
  height: 30px;
  margin-top: -30px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover,.section-footer .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -85px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within,.section-footer .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -85px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.section-footer .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container,.section-footer .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container {
  height: 70px;
  margin-top: -70px
}

.is-touch-device .section-footer .product-gallery--style-bordered .product-gallery__item .product-gallery__content:hover,.is-touch-device .section-footer .product-gallery--style-clean .product-gallery__item .product-gallery__content:hover {
  margin-bottom: 0
}

.is-touch-device .section-footer .product-gallery--style-bordered .product-gallery__item .product-gallery__content:focus-within,.is-touch-device .section-footer .product-gallery--style-clean .product-gallery__item .product-gallery__content:focus-within {
  margin-bottom: 0
}

.is-touch-device .section-footer .product-gallery--style-bordered .product-gallery__item .product-gallery__expand-container,.is-touch-device .section-footer .product-gallery--style-clean .product-gallery__item .product-gallery__expand-container {
  height: auto;
  margin-top: 15px;
  opacity: 1;
  visibility: visible
}

.section-footer .product-gallery-filter__button {
  color: #eb8113
}

.section-footer .btn__default-color {
  outline-color: #eb8113
}

.section-footer .btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.section-footer .btn__default-color.btn-style-flat:hover:not(:disabled),.section-footer .btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.section-footer .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.section-footer .btn__default-color.btn-style-border:hover:not(:disabled),.section-footer .btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.section-footer .btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.section-footer .btn__default-color.btn-style-default:hover:not(:disabled),.section-footer .btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.section-footer .btn--default-color {
  color: #eb8113
}

.section-footer .icon-image__inner {
  background-color: #eb8113
}

.section-footer .image-is-square .icon-image,.section-footer .image-is-rounded .icon-image,.section-footer .image-is-round .icon-image {
  background-color: #eb8113
}

.section-footer .image-is-square .icon-image__inner,.section-footer .image-is-rounded .icon-image__inner,.section-footer .image-is-round .icon-image__inner {
  background-color: #333333
}

@media (max-width: 960px) {
  .section-footer {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .section-footer {
      line-height:1.35;
      font-size: 1.33rem
  }
}

.section-footer a {
  color: #eb8113;
  text-decoration: none
}

.section-footer a:hover {
  color: #f09a41;
  text-decoration: underline
}

.section-footer p,.section-footer ol,.section-footer ul {
  margin-top: 0;
  margin-bottom: .4em
}

.section-footer span[style*='color'] a {
  color: inherit
}

.section-footer button {
  line-height: 1.5
}

.section-footer .text-small {
  font-size: .98rem
}

.section-footer .accent-text-color {
  color: #eb8113
}

.section-footer .accent-background {
  color: #fff;
  background-color: #eb8113
}

.section-footer .accent-background a {
  color: #fff
}

.section-footer .accent-border-link-color {
  border-color: #eb8113
}

.section-footer .accent-border-color {
  border-color: #b1b0ac
}

.section-footer h1,.section-footer h2,.section-footer h3,.section-footer h4,.section-footer h5,.section-footer h6 {
  line-height: 1.1;
  color: #b1b0ac;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .section-footer h1,.section-footer h2,.section-footer h3,.section-footer h4,.section-footer h5,.section-footer h6 {
      line-height:1.1
  }
}

@media (max-width: 960px) {
  .section-footer h1,.section-footer h2,.section-footer h3,.section-footer h4,.section-footer h5,.section-footer h6 {
      line-height:1.1
  }
}

.section-footer h1 a,.section-footer h2 a,.section-footer h3 a,.section-footer h4 a,.section-footer h5 a,.section-footer h6 a {
  text-decoration: underline;
  color: #eb8113
}

.section-footer h1 a:hover,.section-footer h2 a:hover,.section-footer h3 a:hover,.section-footer h4 a:hover,.section-footer h5 a:hover,.section-footer h6 a:hover {
  color: #f09a41
}

.section-footer .product-page .product__price__price {
  color: #b1b0ac
}

.section-footer .heading-200 {
  font-size: 5.6rem;
  margin-top: 3.18rem;
  margin-bottom: 1.16rem
}

@media (max-width: 960px) {
  .section-footer .heading-200 {
      font-size:4.23rem;
      margin-top: 2.77rem;
      margin-bottom: 1.02333333rem
  }
}

@media (max-width: 768px) {
  .section-footer .heading-200 {
      font-size:3.55rem;
      margin-top: 2.565rem;
      margin-bottom: .955rem
  }
}

.section-footer .heading-150 {
  font-size: 4.2rem;
  margin-top: 2.76rem;
  margin-bottom: 1.02rem
}

@media (max-width: 960px) {
  .section-footer .heading-150 {
      font-size:3.3rem;
      margin-top: 2.49rem;
      margin-bottom: .93rem
  }
}

@media (max-width: 768px) {
  .section-footer .heading-150 {
      font-size:2.85rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-footer .heading-130 {
  font-size: 3.64rem;
  margin-top: 2.592rem;
  margin-bottom: .964rem
}

@media (max-width: 960px) {
  .section-footer .heading-130 {
      font-size:2.93rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-footer .heading-130 {
      font-size:2.57rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-footer .heading-120 {
  font-size: 3.36rem;
  margin-top: 2.508rem;
  margin-bottom: .936rem
}

@media (max-width: 960px) {
  .section-footer .heading-120 {
      font-size:2.74rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-footer .heading-120 {
      font-size:2.43rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-footer h1,.section-footer .heading-100 {
  font-size: 2.8rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-footer h1,.section-footer .heading-100 {
      font-size:2.37rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-footer h1,.section-footer .heading-100 {
      font-size:2.15rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-footer h2,.section-footer .heading-85 {
  font-size: 2.38rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-footer h2,.section-footer .heading-85 {
      font-size:2.09rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-footer h2,.section-footer .heading-85 {
      font-size:1.94rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-footer h3,.section-footer .heading-70 {
  font-size: 1.96rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-footer h3,.section-footer .heading-70 {
      font-size:1.81rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-footer h3,.section-footer .heading-70 {
      font-size:1.73rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.section-footer h4 {
  font-size: 1.34rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .section-footer h4 {
      font-size:1.4rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .section-footer h4 {
      font-size:1.42rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

:root {
  --strip__background-color--default: #f9f9f9;
  --strip__background-color--shaded: #ececec;
  --strip__background-color--accent: #eb8113;
  --strip__background-color--inverse: #323232
}

.strip-container--color-default+.strip-container--color-default {
  border-top: 1px solid rgba(112,112,112,0.2)
}

.strip-container--color-shaded {
  background-color: #ececec;
  line-height: 1.5;
  font-size: 1.5rem;
  color: #707070
}

.strip-container--color-shaded+.strip-container--color-shaded {
  border-top: 1px solid rgba(112,112,112,0.2)
}

.strip-container--color-shaded .element-separator,.strip-container--color-shaded .news-post,.strip-container--color-shaded .comment,.strip-container--color-shaded .columns__node--separator-left,.strip-container--color-shaded .columns__node--separator-right,.strip-container--color-shaded .website-logout,.strip-container--color-shaded .element-accordion__heading {
  border-color: rgba(112,112,112,0.2)
}

.strip-container--color-shaded .steps__step {
  background-color: #ececec;
  border-color: rgba(112,112,112,0.3);
  color: #898989
}

a.strip-container--color-shaded .steps__step:hover,a.strip-container--color-shaded .steps__step:focus {
  color: #898989
}

.strip-container--color-shaded .steps__step--enabled {
  color: #707070
}

a.strip-container--color-shaded .steps__step--enabled:hover,a.strip-container--color-shaded .steps__step--enabled:focus {
  color: #707070
}

.strip-container--color-shaded .steps__step--completed {
  background-color: #ece2d7;
  border-color: #eb8113;
  color: #a45a0d
}

a.strip-container--color-shaded .steps__step--completed:hover,a.strip-container--color-shaded .steps__step--completed:focus {
  color: #a45a0d
}

.strip-container--color-shaded .steps__step--selected {
  background-color: #eb8113;
  border-color: #eb8113;
  color: #fff
}

a.strip-container--color-shaded .steps__step--selected:hover,a.strip-container--color-shaded .steps__step--selected:focus {
  color: #fff
}

.strip-container--color-shaded .toggle-block__label {
  border-color: rgba(112,112,112,0.2)
}

input:checked+.strip-container--color-shaded .toggle-block__label {
  border-color: #eb8113;
  box-shadow: 0 0 0 1px #eb8113
}

.strip-container--color-shaded .toggle-block__label.element-form-is-error,input:checked+.strip-container--color-shaded .toggle-block__label.element-form-is-error {
  border-color: #d9534f
}

.strip-container--color-shaded .element-form-well {
  background: #e2e2e2
}

.strip-container--color-shaded .element-form-input-text {
  border-color: rgba(147,147,147,0.5);
  color: #707070;
  background-color: #fff;
  box-shadow: 0 0 rgba(0,0,0,0)
}

.strip-container--color-shaded .element-form-input-text:hover {
  border-color: #939393
}

.strip-container--color-shaded .element-form-input-text:focus {
  border-color: #eb8113;
  box-shadow: 0 0 0 2px #ecb780,0 0 rgba(0,0,0,0)
}

.strip-container--color-shaded input[type='radio']:focus,.strip-container--color-shaded input[type='checkbox']:focus {
  box-shadow: 0 0 0 2px #eb8113;
  outline: none
}

.strip-container--color-shaded input[type='radio']:focus:not(:focus-visible),.strip-container--color-shaded input[type='checkbox']:focus:not(:focus-visible) {
  box-shadow: none
}

.strip-container--color-shaded .pagination__page {
  color: #eb8113
}

.strip-container--color-shaded .pagination__page:hover {
  background: rgba(235,129,19,0.15);
  color: #eb8113
}

.strip-container--color-shaded .pagination__page--active,.strip-container--color-shaded .pagination__page--active:hover {
  box-shadow: inset 0 0 0 2px #eb8113;
  background: transparent;
  color: #eb8113
}

.strip-container--color-shaded .product-sticker {
  color: #fff;
  background: #eb8113
}

.strip-container--color-shaded .text--muted {
  color: #a2a2a2
}

.strip-container--color-shaded .element-social-share .share-button--wrapper {
  border-color: rgba(112,112,112,0.2)
}

.strip-container--color-shaded .element-rating--color-accent .element-rating__icon-full {
  color: #eb8113
}

.strip-container--color-shaded .element-rating__icon-empty {
  color: #c7c7c7
}

.strip-container--color-shaded .element-audio {
  --plyr-color-main: #eb8113;
  --plyr-audio-controls-background: #fff;
  --plyr-audio-control-color: #707070;
  --plyr-audio-control-background-hover: #eb8113;
  --plyr-audio-control-color-hover: #fff;
  --plyr-audio-progress-buffered-background: rgba(112,112,112,0.3);
  --plyr-control-radius: 0
}

.strip-container--color-shaded .element-audio .plyr--audio .plyr__controls {
  border-radius: 0;
  border-color: #d3d3d3
}

.strip-container--color-shaded .element-video {
  --plyr-color-main: #eb8113;
  --plyr-video-control-background-hover: #eb8113;
  --plyr-video-control-color-hover: #fff
}

.strip-container--color-shaded .element-social-follow--color-default.element-social-follow--style-border .element-social-follow-profile,.strip-container--color-shaded .element-social-follow--color-default.element-social-follow--style-icononly .element-social-follow-profile {
  color: #eb8113;
  border-color: #eb8113
}

.strip-container--color-shaded .element-social-follow--color-default.element-social-follow--style-solid .element-social-follow-profile {
  background-color: #eb8113;
  color: #fff
}

.strip-container--color-shaded .element-social-follow__default-color-getter {
  color: #eb8113
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-facebook {
  background-color: #3b5998
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-facebook,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-facebook {
  color: #3b5998;
  border-color: #3b5998
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-twitter {
  background-color: #000
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-twitter,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-twitter {
  color: #000;
  border-color: #000
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-pinterest {
  background-color: #e60023
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-pinterest,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-pinterest {
  color: #e60023;
  border-color: #e60023
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-instagram {
  background-color: #e1306c
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-instagram,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-instagram {
  color: #e1306c;
  border-color: #e1306c
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-youtube {
  background-color: #ff0000
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-youtube,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-youtube {
  color: #ff0000;
  border-color: #ff0000
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tumblr {
  background-color: #35465c
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tumblr,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tumblr {
  color: #35465c;
  border-color: #35465c
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-linkedin {
  background-color: #0077b5
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-linkedin,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-linkedin {
  color: #0077b5;
  border-color: #0077b5
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tiktok {
  background-color: #010101
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tiktok,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tiktok {
  color: #010101;
  border-color: #010101
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-whatsapp {
  background-color: #25d366
}

.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-whatsapp,.strip-container--color-shaded .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-whatsapp {
  color: #21bd5c;
  border-color: #21bd5c
}

.strip-container--color-shaded .image-gallery__main:has(.image-gallery__slides :focus-visible) {
  outline: 1px dotted #eb8113;
  outline-offset: 1px
}

.strip-container--color-shaded .image-gallery__thumbnail-item {
  border-color: rgba(112,112,112,0.3)
}

.strip-container--color-shaded .image-gallery__thumbnail-item:hover {
  border-color: rgba(112,112,112,0.6)
}

.strip-container--color-shaded .image-gallery__thumbnail-item.image-gallery__thumbnail-item--active {
  border-color: #eb8113
}

.strip-container--color-shaded .element-accordion--style-box .strip-container--color-shaded .element-accordion__heading {
  background-color: #eb8113;
  color: #fff;
  border-color: #bc670f
}

.strip-container--color-shaded .element-accordion--style-box .strip-container--color-shaded .element-accordion__item {
  background-color: rgba(112,112,112,0.05)
}

.strip-container--color-shaded .element-accordion--style-box .strip-container--color-shaded .element-accordion__content {
  border-color: #bc670f
}

.strip-container--color-shaded .table.table--header.table--border th,.strip-container--color-shaded .table.table--header.table--striped th {
  background-color: #eb8113;
  color: #fff
}

.strip-container--color-shaded .table.table--border tr,.strip-container--color-shaded .table.table--striped tr td {
  border-top: 1px solid rgba(112,112,112,0.1)
}

.strip-container--color-shaded .table.table--striped tr:nth-child(odd) td {
  background-color: rgba(112,112,112,0.05)
}

.strip-container--color-shaded .table.table--hover tr:hover td {
  background-color: rgba(112,112,112,0.15)
}

.strip-container--color-shaded .table.table--nowrap {
  white-space: nowrap
}

.strip-container--color-shaded .responsive-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(112,112,112,0.3)
}

.strip-container--color-shaded .handsontable .table--header.table--border tr:first-child td,.strip-container--color-shaded .handsontable .table--header.table--striped tr:first-child td {
  background-color: #eb8113;
  color: #fff
}

.strip-container--color-shaded .product,.strip-container--color-shaded .product__description,.strip-container--color-shaded .product-page__description,.strip-container--color-shaded .product-page__social {
  border-color: rgba(112,112,112,0.2)
}

.strip-container--color-shaded .columns .product .product,.strip-container--color-shaded .image-gallery__thumbnail-item {
  border-radius: 0
}

.strip-container--color-shaded .product__free-shipping-motivator {
  color: #eb8113
}

.strip-container--color-shaded .product-gallery--empty {
  border: 1px solid rgba(112,112,112,0.3);
  color: rgba(112,112,112,0.5)
}

.strip-container--color-shaded .product-gallery__content {
  background: #ececec
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__content {
  border-color: rgba(112,112,112,0.3)
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__content:hover,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__content:hover {
  background: #fcfcfc;
  border-color: rgba(112,112,112,0.6)
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__content:focus-within,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__content:focus-within {
  background: #fcfcfc;
  border-color: rgba(112,112,112,0.6)
}

.strip-container--color-shaded .product-gallery--style-image-only .product-gallery__info-container {
  background-color: rgba(252,252,252,0.9)
}

.strip-container--color-shaded .product-gallery__price {
  color: #959595
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__expand-container button,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__expand-container button,.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__expand-container select,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__expand-container select {
  height: 32px;
  box-sizing: border-box
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover,.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -47px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within,.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -47px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__expand-container {
  height: 32px;
  margin-top: -32px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -89px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -89px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container,.strip-container--color-shaded .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container {
  height: 74px;
  margin-top: -74px
}

.is-touch-device .strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item .product-gallery__content:hover,.is-touch-device .strip-container--color-shaded .product-gallery--style-clean .product-gallery__item .product-gallery__content:hover {
  margin-bottom: 0
}

.is-touch-device .strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item .product-gallery__content:focus-within,.is-touch-device .strip-container--color-shaded .product-gallery--style-clean .product-gallery__item .product-gallery__content:focus-within {
  margin-bottom: 0
}

.is-touch-device .strip-container--color-shaded .product-gallery--style-bordered .product-gallery__item .product-gallery__expand-container,.is-touch-device .strip-container--color-shaded .product-gallery--style-clean .product-gallery__item .product-gallery__expand-container {
  height: auto;
  margin-top: 15px;
  opacity: 1;
  visibility: visible
}

.strip-container--color-shaded .product-gallery-filter__button {
  color: #eb8113
}

.strip-container--color-shaded .btn__default-color {
  outline-color: #eb8113
}

.strip-container--color-shaded .btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.strip-container--color-shaded .btn__default-color.btn-style-flat:hover:not(:disabled),.strip-container--color-shaded .btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.strip-container--color-shaded .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.strip-container--color-shaded .btn__default-color.btn-style-border:hover:not(:disabled),.strip-container--color-shaded .btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.strip-container--color-shaded .btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.strip-container--color-shaded .btn__default-color.btn-style-default:hover:not(:disabled),.strip-container--color-shaded .btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.strip-container--color-shaded .btn--default-color {
  color: #eb8113
}

.strip-container--color-shaded .icon-image__inner {
  background-color: #eb8113
}

.strip-container--color-shaded .image-is-square .icon-image,.strip-container--color-shaded .image-is-rounded .icon-image,.strip-container--color-shaded .image-is-round .icon-image {
  background-color: #eb8113
}

.strip-container--color-shaded .image-is-square .icon-image__inner,.strip-container--color-shaded .image-is-rounded .icon-image__inner,.strip-container--color-shaded .image-is-round .icon-image__inner {
  background-color: #ececec
}

@media (max-width: 960px) {
  .strip-container--color-shaded {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded {
      line-height:1.35;
      font-size: 1.37rem
  }
}

.strip-container--color-shaded a {
  color: #eb8113;
  text-decoration: none
}

.strip-container--color-shaded a:hover {
  color: #f09a41;
  text-decoration: underline
}

.strip-container--color-shaded p,.strip-container--color-shaded ol,.strip-container--color-shaded ul {
  margin-top: 0;
  margin-bottom: .4em
}

.strip-container--color-shaded span[style*='color'] a {
  color: inherit
}

.strip-container--color-shaded button {
  line-height: 1.5
}

.strip-container--color-shaded .text-small {
  font-size: 1.05rem
}

.strip-container--color-shaded .accent-text-color {
  color: #eb8113
}

.strip-container--color-shaded .accent-background {
  color: #fff;
  background-color: #eb8113
}

.strip-container--color-shaded .accent-background a {
  color: #fff
}

.strip-container--color-shaded .accent-border-link-color {
  border-color: #eb8113
}

.strip-container--color-shaded .accent-border-color {
  border-color: #707070
}

.strip-container--color-shaded h1,.strip-container--color-shaded h2,.strip-container--color-shaded h3,.strip-container--color-shaded h4,.strip-container--color-shaded h5,.strip-container--color-shaded h6 {
  line-height: 1.1;
  color: #eb8113;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .strip-container--color-shaded h1,.strip-container--color-shaded h2,.strip-container--color-shaded h3,.strip-container--color-shaded h4,.strip-container--color-shaded h5,.strip-container--color-shaded h6 {
      line-height:1.1
  }
}

@media (max-width: 960px) {
  .strip-container--color-shaded h1,.strip-container--color-shaded h2,.strip-container--color-shaded h3,.strip-container--color-shaded h4,.strip-container--color-shaded h5,.strip-container--color-shaded h6 {
      line-height:1.1
  }
}

.strip-container--color-shaded h1 a,.strip-container--color-shaded h2 a,.strip-container--color-shaded h3 a,.strip-container--color-shaded h4 a,.strip-container--color-shaded h5 a,.strip-container--color-shaded h6 a {
  text-decoration: underline;
  color: #eb8113
}

.strip-container--color-shaded h1 a:hover,.strip-container--color-shaded h2 a:hover,.strip-container--color-shaded h3 a:hover,.strip-container--color-shaded h4 a:hover,.strip-container--color-shaded h5 a:hover,.strip-container--color-shaded h6 a:hover {
  color: #f09a41
}

.strip-container--color-shaded .product-page .product__price__price {
  color: #eb8113
}

.strip-container--color-shaded .heading-200 {
  font-size: 6rem;
  margin-top: 3.3rem;
  margin-bottom: 1.2rem
}

@media (max-width: 960px) {
  .strip-container--color-shaded .heading-200 {
      font-size:4.5rem;
      margin-top: 2.85rem;
      margin-bottom: 1.05rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded .heading-200 {
      font-size:3.75rem;
      margin-top: 2.625rem;
      margin-bottom: .975rem
  }
}

.strip-container--color-shaded .heading-150 {
  font-size: 4.5rem;
  margin-top: 2.85rem;
  margin-bottom: 1.05rem
}

@media (max-width: 960px) {
  .strip-container--color-shaded .heading-150 {
      font-size:3.5rem;
      margin-top: 2.55rem;
      margin-bottom: .95rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded .heading-150 {
      font-size:3rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-shaded .heading-130 {
  font-size: 3.9rem;
  margin-top: 2.67rem;
  margin-bottom: .99rem
}

@media (max-width: 960px) {
  .strip-container--color-shaded .heading-130 {
      font-size:3.1rem;
      margin-top: 2.43rem;
      margin-bottom: .91rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded .heading-130 {
      font-size:2.7rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-shaded .heading-120 {
  font-size: 3.6rem;
  margin-top: 2.58rem;
  margin-bottom: .96rem
}

@media (max-width: 960px) {
  .strip-container--color-shaded .heading-120 {
      font-size:2.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded .heading-120 {
      font-size:2.55rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-shaded h1,.strip-container--color-shaded .heading-100 {
  font-size: 3rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-shaded h1,.strip-container--color-shaded .heading-100 {
      font-size:2.5rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded h1,.strip-container--color-shaded .heading-100 {
      font-size:2.25rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-shaded h2,.strip-container--color-shaded .heading-85 {
  font-size: 2.55rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-shaded h2,.strip-container--color-shaded .heading-85 {
      font-size:2.2rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded h2,.strip-container--color-shaded .heading-85 {
      font-size:2.02rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-shaded h3,.strip-container--color-shaded .heading-70 {
  font-size: 2.1rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-shaded h3,.strip-container--color-shaded .heading-70 {
      font-size:1.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded h3,.strip-container--color-shaded .heading-70 {
      font-size:1.8rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-shaded h4 {
  font-size: 1.44rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-shaded h4 {
      font-size:1.46rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-shaded h4 {
      font-size:1.47rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent {
  background-color: #eb8113;
  line-height: 1.5;
  font-size: 1.5rem;
  color: #fff
}

.strip-container--color-accent+.strip-container--color-accent {
  border-top: 1px solid rgba(255,255,255,0.2)
}

.strip-container--color-accent .element-separator,.strip-container--color-accent .news-post,.strip-container--color-accent .comment,.strip-container--color-accent .columns__node--separator-left,.strip-container--color-accent .columns__node--separator-right,.strip-container--color-accent .website-logout,.strip-container--color-accent .element-accordion__heading {
  border-color: rgba(255,255,255,0.2)
}

.strip-container--color-accent .steps__step {
  background-color: #eb8113;
  border-color: rgba(255,255,255,0.3);
  color: #fbe6d0
}

a.strip-container--color-accent .steps__step:hover,a.strip-container--color-accent .steps__step:focus {
  color: #fbe6d0
}

.strip-container--color-accent .steps__step--enabled {
  color: #fff
}

a.strip-container--color-accent .steps__step--enabled:hover,a.strip-container--color-accent .steps__step--enabled:focus {
  color: #fff
}

.strip-container--color-accent .steps__step--completed {
  background-color: #e27c12;
  border-color: #8d4d0b;
  color: #462606
}

a.strip-container--color-accent .steps__step--completed:hover,a.strip-container--color-accent .steps__step--completed:focus {
  color: #462606
}

.strip-container--color-accent .steps__step--selected {
  background-color: #8d4d0b;
  border-color: #8d4d0b;
  color: #fff
}

a.strip-container--color-accent .steps__step--selected:hover,a.strip-container--color-accent .steps__step--selected:focus {
  color: #fff
}

.strip-container--color-accent .toggle-block__label {
  border-color: rgba(255,255,255,0.2)
}

input:checked+.strip-container--color-accent .toggle-block__label {
  border-color: #8d4d0b;
  box-shadow: 0 0 0 1px #8d4d0b
}

.strip-container--color-accent .toggle-block__label.element-form-is-error,input:checked+.strip-container--color-accent .toggle-block__label.element-form-is-error {
  border-color: #d9534f
}

.strip-container--color-accent .element-form-well {
  background: #ed8b26
}

.strip-container--color-accent .element-form-input-text {
  border-color: rgba(249,217,183,0.5);
  color: #754009;
  background-color: #f09a41;
  box-shadow: 0 0 rgba(0,0,0,0)
}

.strip-container--color-accent .element-form-input-text:hover {
  border-color: #f9d9b7
}

.strip-container--color-accent .element-form-input-text:focus {
  border-color: #8d4d0b;
  box-shadow: 0 0 0 2px #bc670f,0 0 rgba(0,0,0,0)
}

.strip-container--color-accent select.element-form-input-text {
  background-image: url('data:image/svg+xml;charset=UTF-8, %3Csvg%20width%3D%2221%22%20height%3D%2211%22%20viewBox%3D%220%200%2021%2011%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M10.3205%2010.7254L0.320511%200.33309L20.3205%200.333092L10.3205%2010.7254Z%22%20fill%3D%22%23754009%22%20/%3E%3C/svg%3E')
}

.strip-container--color-accent input[type='radio']:focus,.strip-container--color-accent input[type='checkbox']:focus {
  box-shadow: 0 0 0 2px #8d4d0b;
  outline: none
}

.strip-container--color-accent input[type='radio']:focus:not(:focus-visible),.strip-container--color-accent input[type='checkbox']:focus:not(:focus-visible) {
  box-shadow: none
}

.strip-container--color-accent .pagination__page {
  color: #8d4d0b
}

.strip-container--color-accent .pagination__page:hover {
  background: rgba(141,77,11,0.15);
  color: #8d4d0b
}

.strip-container--color-accent .pagination__page--active,.strip-container--color-accent .pagination__page--active:hover {
  box-shadow: inset 0 0 0 2px #8d4d0b;
  background: transparent;
  color: #8d4d0b
}

.strip-container--color-accent .product-sticker {
  color: #fff;
  background: #8d4d0b
}

.strip-container--color-accent .text--muted {
  color: #f7cda1
}

.strip-container--color-accent .element-social-share .share-button--wrapper {
  border-color: rgba(255,255,255,0.2)
}

.strip-container--color-accent .element-rating--color-accent .element-rating__icon-full {
  color: #8d4d0b
}

.strip-container--color-accent .element-rating__icon-empty {
  color: #f1a75a
}

.strip-container--color-accent .element-audio {
  --plyr-color-main: #8d4d0b;
  --plyr-audio-controls-background: #f5c28d;
  --plyr-audio-control-color: #6e5740;
  --plyr-audio-control-background-hover: #8d4d0b;
  --plyr-audio-control-color-hover: #fff;
  --plyr-audio-progress-buffered-background: rgba(110,87,64,0.3);
  --plyr-control-radius: 0
}

.strip-container--color-accent .element-audio .plyr--audio .plyr__controls {
  border-radius: 0;
  border-color: #d2791c
}

.strip-container--color-accent .element-video {
  --plyr-color-main: #8d4d0b;
  --plyr-video-control-background-hover: #8d4d0b;
  --plyr-video-control-color-hover: #fff
}

.strip-container--color-accent .element-social-follow--color-default.element-social-follow--style-border .element-social-follow-profile,.strip-container--color-accent .element-social-follow--color-default.element-social-follow--style-icononly .element-social-follow-profile {
  color: #8d4d0b;
  border-color: #8d4d0b
}

.strip-container--color-accent .element-social-follow--color-default.element-social-follow--style-solid .element-social-follow-profile {
  background-color: #8d4d0b;
  color: #fff
}

.strip-container--color-accent .element-social-follow__default-color-getter {
  color: #8d4d0b
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-facebook {
  background-color: #3b5998
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-facebook,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-facebook {
  color: #3b5998;
  border-color: #3b5998
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-twitter {
  background-color: #000
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-twitter,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-twitter {
  color: #000;
  border-color: #000
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-pinterest {
  background-color: #e60023
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-pinterest,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-pinterest {
  color: #d20020;
  border-color: #d20020
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-instagram {
  background-color: #e1306c
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-instagram,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-instagram {
  color: #c61d56;
  border-color: #c61d56
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-youtube {
  background-color: #ff0000
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-youtube,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-youtube {
  color: #d10000;
  border-color: #d10000
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tumblr {
  background-color: #35465c
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tumblr,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tumblr {
  color: #35465c;
  border-color: #35465c
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-linkedin {
  background-color: #0077b5
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-linkedin,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-linkedin {
  color: #006da6;
  border-color: #006da6
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tiktok {
  background-color: #010101
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tiktok,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tiktok {
  color: #010101;
  border-color: #010101
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-whatsapp {
  background-color: #25d366
}

.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-whatsapp,.strip-container--color-accent .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-whatsapp {
  color: #15783a;
  border-color: #15783a
}

.strip-container--color-accent .image-gallery__main:has(.image-gallery__slides :focus-visible) {
  outline: 1px dotted #8d4d0b;
  outline-offset: 1px
}

.strip-container--color-accent .image-gallery__thumbnail-item {
  border-color: rgba(255,255,255,0.3)
}

.strip-container--color-accent .image-gallery__thumbnail-item:hover {
  border-color: rgba(255,255,255,0.6)
}

.strip-container--color-accent .image-gallery__thumbnail-item.image-gallery__thumbnail-item--active {
  border-color: #8d4d0b
}

.strip-container--color-accent .element-accordion--style-box .strip-container--color-accent .element-accordion__heading {
  background-color: #8d4d0b;
  color: #fff;
  border-color: #5d3308
}

.strip-container--color-accent .element-accordion--style-box .strip-container--color-accent .element-accordion__item {
  background-color: rgba(255,255,255,0.05)
}

.strip-container--color-accent .element-accordion--style-box .strip-container--color-accent .element-accordion__content {
  border-color: #5d3308
}

.strip-container--color-accent .table.table--header.table--border th,.strip-container--color-accent .table.table--header.table--striped th {
  background-color: #8d4d0b;
  color: #fff
}

.strip-container--color-accent .table.table--border tr,.strip-container--color-accent .table.table--striped tr td {
  border-top: 1px solid rgba(255,255,255,0.1)
}

.strip-container--color-accent .table.table--striped tr:nth-child(odd) td {
  background-color: rgba(255,255,255,0.05)
}

.strip-container--color-accent .table.table--hover tr:hover td {
  background-color: rgba(255,255,255,0.15)
}

.strip-container--color-accent .table.table--nowrap {
  white-space: nowrap
}

.strip-container--color-accent .responsive-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.3)
}

.strip-container--color-accent .handsontable .table--header.table--border tr:first-child td,.strip-container--color-accent .handsontable .table--header.table--striped tr:first-child td {
  background-color: #8d4d0b;
  color: #fff
}

.strip-container--color-accent .product,.strip-container--color-accent .product__description,.strip-container--color-accent .product-page__description,.strip-container--color-accent .product-page__social {
  border-color: rgba(255,255,255,0.2)
}

.strip-container--color-accent .columns .product .product,.strip-container--color-accent .image-gallery__thumbnail-item {
  border-radius: 0
}

.strip-container--color-accent .product__free-shipping-motivator {
  color: #8d4d0b
}

.strip-container--color-accent .product-gallery--empty {
  border: 1px solid rgba(255,255,255,0.3);
  color: rgba(255,255,255,0.5)
}

.strip-container--color-accent .product-gallery__content {
  background: #eb8113
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__content {
  border-color: rgba(255,255,255,0.3)
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__content:hover,.strip-container--color-accent .product-gallery--style-clean .product-gallery__content:hover {
  background: #ee902e;
  border-color: rgba(255,255,255,0.6)
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__content:focus-within,.strip-container--color-accent .product-gallery--style-clean .product-gallery__content:focus-within {
  background: #ee902e;
  border-color: rgba(255,255,255,0.6)
}

.strip-container--color-accent .product-gallery--style-image-only .product-gallery__info-container {
  background-color: rgba(238,144,46,0.9)
}

.strip-container--color-accent .product-gallery__price {
  color: #f9d9b8
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__expand-container button,.strip-container--color-accent .product-gallery--style-clean .product-gallery__expand-container button,.strip-container--color-accent .product-gallery--style-bordered .product-gallery__expand-container select,.strip-container--color-accent .product-gallery--style-clean .product-gallery__expand-container select {
  height: 32px;
  box-sizing: border-box
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover,.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -47px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within,.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -47px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__expand-container {
  height: 32px;
  margin-top: -32px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -89px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -89px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-accent .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container,.strip-container--color-accent .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container {
  height: 74px;
  margin-top: -74px
}

.is-touch-device .strip-container--color-accent .product-gallery--style-bordered .product-gallery__item .product-gallery__content:hover,.is-touch-device .strip-container--color-accent .product-gallery--style-clean .product-gallery__item .product-gallery__content:hover {
  margin-bottom: 0
}

.is-touch-device .strip-container--color-accent .product-gallery--style-bordered .product-gallery__item .product-gallery__content:focus-within,.is-touch-device .strip-container--color-accent .product-gallery--style-clean .product-gallery__item .product-gallery__content:focus-within {
  margin-bottom: 0
}

.is-touch-device .strip-container--color-accent .product-gallery--style-bordered .product-gallery__item .product-gallery__expand-container,.is-touch-device .strip-container--color-accent .product-gallery--style-clean .product-gallery__item .product-gallery__expand-container {
  height: auto;
  margin-top: 15px;
  opacity: 1;
  visibility: visible
}

.strip-container--color-accent .product-gallery-filter__button {
  color: #8d4d0b
}

.strip-container--color-accent .btn__default-color {
  outline-color: #8d4d0b
}

.strip-container--color-accent .btn__default-color.btn-style-flat {
  background: #8d4d0b;
  color: #fff
}

.strip-container--color-accent .btn__default-color.btn-style-flat:hover:not(:disabled),.strip-container--color-accent .btn__default-color.btn-style-flat.btn__active {
  background: #bc670f;
  color: #fff
}

.strip-container--color-accent .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #8d4d0b;
  color: #8d4d0b
}

.strip-container--color-accent .btn__default-color.btn-style-border:hover:not(:disabled),.strip-container--color-accent .btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #8d4d0b
}

.strip-container--color-accent .btn__default-color.btn-style-default {
  background: #8d4d0b;
  color: #fff
}

.strip-container--color-accent .btn__default-color.btn-style-default:hover:not(:disabled),.strip-container--color-accent .btn__default-color.btn-style-default.btn__active {
  background: #bc670f;
  color: #fff
}

.strip-container--color-accent .btn--default-color {
  color: #8d4d0b
}

.strip-container--color-accent .icon-image__inner {
  background-color: #8d4d0b
}

.strip-container--color-accent .image-is-square .icon-image,.strip-container--color-accent .image-is-rounded .icon-image,.strip-container--color-accent .image-is-round .icon-image {
  background-color: #8d4d0b
}

.strip-container--color-accent .image-is-square .icon-image__inner,.strip-container--color-accent .image-is-rounded .icon-image__inner,.strip-container--color-accent .image-is-round .icon-image__inner {
  background-color: #eb8113
}

@media (max-width: 960px) {
  .strip-container--color-accent {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent {
      line-height:1.35;
      font-size: 1.37rem
  }
}

.strip-container--color-accent a {
  color: #8d4d0b;
  text-decoration: none
}

.strip-container--color-accent a:hover {
  color: #bc670f;
  text-decoration: underline
}

.strip-container--color-accent p,.strip-container--color-accent ol,.strip-container--color-accent ul {
  margin-top: 0;
  margin-bottom: .4em
}

.strip-container--color-accent span[style*='color'] a {
  color: inherit
}

.strip-container--color-accent button {
  line-height: 1.5
}

.strip-container--color-accent .text-small {
  font-size: 1.05rem
}

.strip-container--color-accent .accent-text-color {
  color: #8d4d0b
}

.strip-container--color-accent .accent-background {
  color: #f9d9b7;
  background-color: #8d4d0b
}

.strip-container--color-accent .accent-background a {
  color: #f9d9b7
}

.strip-container--color-accent .accent-border-link-color {
  border-color: #8d4d0b
}

.strip-container--color-accent .accent-border-color {
  border-color: #fff
}

.strip-container--color-accent h1,.strip-container--color-accent h2,.strip-container--color-accent h3,.strip-container--color-accent h4,.strip-container--color-accent h5,.strip-container--color-accent h6 {
  line-height: 1.1;
  color: #fff;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .strip-container--color-accent h1,.strip-container--color-accent h2,.strip-container--color-accent h3,.strip-container--color-accent h4,.strip-container--color-accent h5,.strip-container--color-accent h6 {
      line-height:1.1
  }
}

@media (max-width: 960px) {
  .strip-container--color-accent h1,.strip-container--color-accent h2,.strip-container--color-accent h3,.strip-container--color-accent h4,.strip-container--color-accent h5,.strip-container--color-accent h6 {
      line-height:1.1
  }
}

.strip-container--color-accent h1 a,.strip-container--color-accent h2 a,.strip-container--color-accent h3 a,.strip-container--color-accent h4 a,.strip-container--color-accent h5 a,.strip-container--color-accent h6 a {
  text-decoration: underline;
  color: #8d4d0b
}

.strip-container--color-accent h1 a:hover,.strip-container--color-accent h2 a:hover,.strip-container--color-accent h3 a:hover,.strip-container--color-accent h4 a:hover,.strip-container--color-accent h5 a:hover,.strip-container--color-accent h6 a:hover {
  color: #bc670f
}

.strip-container--color-accent .product-page .product__price__price {
  color: #fff
}

.strip-container--color-accent .heading-200 {
  font-size: 6rem;
  margin-top: 3.3rem;
  margin-bottom: 1.2rem
}

@media (max-width: 960px) {
  .strip-container--color-accent .heading-200 {
      font-size:4.5rem;
      margin-top: 2.85rem;
      margin-bottom: 1.05rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent .heading-200 {
      font-size:3.75rem;
      margin-top: 2.625rem;
      margin-bottom: .975rem
  }
}

.strip-container--color-accent .heading-150 {
  font-size: 4.5rem;
  margin-top: 2.85rem;
  margin-bottom: 1.05rem
}

@media (max-width: 960px) {
  .strip-container--color-accent .heading-150 {
      font-size:3.5rem;
      margin-top: 2.55rem;
      margin-bottom: .95rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent .heading-150 {
      font-size:3rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent .heading-130 {
  font-size: 3.9rem;
  margin-top: 2.67rem;
  margin-bottom: .99rem
}

@media (max-width: 960px) {
  .strip-container--color-accent .heading-130 {
      font-size:3.1rem;
      margin-top: 2.43rem;
      margin-bottom: .91rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent .heading-130 {
      font-size:2.7rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent .heading-120 {
  font-size: 3.6rem;
  margin-top: 2.58rem;
  margin-bottom: .96rem
}

@media (max-width: 960px) {
  .strip-container--color-accent .heading-120 {
      font-size:2.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent .heading-120 {
      font-size:2.55rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent h1,.strip-container--color-accent .heading-100 {
  font-size: 3rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-accent h1,.strip-container--color-accent .heading-100 {
      font-size:2.5rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent h1,.strip-container--color-accent .heading-100 {
      font-size:2.25rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent h2,.strip-container--color-accent .heading-85 {
  font-size: 2.55rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-accent h2,.strip-container--color-accent .heading-85 {
      font-size:2.2rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent h2,.strip-container--color-accent .heading-85 {
      font-size:2.02rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent h3,.strip-container--color-accent .heading-70 {
  font-size: 2.1rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-accent h3,.strip-container--color-accent .heading-70 {
      font-size:1.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent h3,.strip-container--color-accent .heading-70 {
      font-size:1.8rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-accent h4 {
  font-size: 1.44rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-accent h4 {
      font-size:1.46rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-accent h4 {
      font-size:1.47rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse {
  background-color: #323232;
  line-height: 1.5;
  font-size: 1.5rem;
  color: #a3a3a3
}

.strip-container--color-inverse+.strip-container--color-inverse {
  border-top: 1px solid rgba(163,163,163,0.2)
}

.strip-container--color-inverse .element-separator,.strip-container--color-inverse .news-post,.strip-container--color-inverse .comment,.strip-container--color-inverse .columns__node--separator-left,.strip-container--color-inverse .columns__node--separator-right,.strip-container--color-inverse .website-logout,.strip-container--color-inverse .element-accordion__heading {
  border-color: rgba(163,163,163,0.2)
}

.strip-container--color-inverse .steps__step {
  background-color: #323232;
  border-color: rgba(163,163,163,0.3);
  color: #8c8c8c
}

a.strip-container--color-inverse .steps__step:hover,a.strip-container--color-inverse .steps__step:focus {
  color: #8c8c8c
}

.strip-container--color-inverse .steps__step--enabled {
  color: #a3a3a3
}

a.strip-container--color-inverse .steps__step--enabled:hover,a.strip-container--color-inverse .steps__step--enabled:focus {
  color: #a3a3a3
}

.strip-container--color-inverse .steps__step--completed {
  background-color: #443a2f;
  border-color: #eb8113;
  color: #f2a759
}

a.strip-container--color-inverse .steps__step--completed:hover,a.strip-container--color-inverse .steps__step--completed:focus {
  color: #f2a759
}

.strip-container--color-inverse .steps__step--selected {
  background-color: #eb8113;
  border-color: #eb8113;
  color: #fff
}

a.strip-container--color-inverse .steps__step--selected:hover,a.strip-container--color-inverse .steps__step--selected:focus {
  color: #fff
}

.strip-container--color-inverse .toggle-block__label {
  border-color: rgba(163,163,163,0.2)
}

input:checked+.strip-container--color-inverse .toggle-block__label {
  border-color: #eb8113;
  box-shadow: 0 0 0 1px #eb8113
}

.strip-container--color-inverse .toggle-block__label.element-form-is-error,input:checked+.strip-container--color-inverse .toggle-block__label.element-form-is-error {
  border-color: #d9534f
}

.strip-container--color-inverse .element-form-well {
  background: #3b3b3b
}

.strip-container--color-inverse .element-form-input-text {
  border-color: rgba(139,139,139,0.5);
  color: #a3a3a3;
  background-color: #4b4b4b;
  box-shadow: 0 0 rgba(0,0,0,0)
}

.strip-container--color-inverse .element-form-input-text:hover {
  border-color: #8b8b8b
}

.strip-container--color-inverse .element-form-input-text:focus {
  border-color: #eb8113;
  box-shadow: 0 0 0 2px #8e5922,0 0 rgba(0,0,0,0)
}

.strip-container--color-inverse select.element-form-input-text {
  background-image: url('data:image/svg+xml;charset=UTF-8, %3Csvg%20width%3D%2221%22%20height%3D%2211%22%20viewBox%3D%220%200%2021%2011%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M10.3205%2010.7254L0.320511%200.33309L20.3205%200.333092L10.3205%2010.7254Z%22%20fill%3D%22%23a3a3a3%22%20/%3E%3C/svg%3E')
}

.strip-container--color-inverse input[type='radio']:focus,.strip-container--color-inverse input[type='checkbox']:focus {
  box-shadow: 0 0 0 2px #eb8113;
  outline: none
}

.strip-container--color-inverse input[type='radio']:focus:not(:focus-visible),.strip-container--color-inverse input[type='checkbox']:focus:not(:focus-visible) {
  box-shadow: none
}

.strip-container--color-inverse .pagination__page {
  color: #eb8113
}

.strip-container--color-inverse .pagination__page:hover {
  background: rgba(235,129,19,0.15);
  color: #eb8113
}

.strip-container--color-inverse .pagination__page--active,.strip-container--color-inverse .pagination__page--active:hover {
  box-shadow: inset 0 0 0 2px #eb8113;
  background: transparent;
  color: #eb8113
}

.strip-container--color-inverse .product-sticker {
  color: #fff;
  background: #eb8113
}

.strip-container--color-inverse .text--muted {
  color: #767676
}

.strip-container--color-inverse .element-social-share .share-button--wrapper {
  border-color: rgba(163,163,163,0.2)
}

.strip-container--color-inverse .element-rating--color-accent .element-rating__icon-full {
  color: #eb8113
}

.strip-container--color-inverse .element-rating__icon-empty {
  color: #545454
}

.strip-container--color-inverse .element-audio {
  --plyr-color-main: #eb8113;
  --plyr-audio-controls-background: #585858;
  --plyr-audio-control-color: #e4e4e4;
  --plyr-audio-control-background-hover: #eb8113;
  --plyr-audio-control-color-hover: #fff;
  --plyr-audio-progress-buffered-background: rgba(228,228,228,0.3);
  --plyr-control-radius: 0
}

.strip-container--color-inverse .element-audio .plyr--audio .plyr__controls {
  border-radius: 0;
  border-color: #747474
}

.strip-container--color-inverse .element-video {
  --plyr-color-main: #eb8113;
  --plyr-video-control-background-hover: #eb8113;
  --plyr-video-control-color-hover: #fff
}

.strip-container--color-inverse .element-social-follow--color-default.element-social-follow--style-border .element-social-follow-profile,.strip-container--color-inverse .element-social-follow--color-default.element-social-follow--style-icononly .element-social-follow-profile {
  color: #eb8113;
  border-color: #eb8113
}

.strip-container--color-inverse .element-social-follow--color-default.element-social-follow--style-solid .element-social-follow-profile {
  background-color: #eb8113;
  color: #fff
}

.strip-container--color-inverse .element-social-follow__default-color-getter {
  color: #eb8113
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-facebook {
  background-color: #3b5998
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-facebook,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-facebook {
  color: #3e5d9f;
  border-color: #3e5d9f
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-twitter {
  background-color: #000
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-twitter,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-twitter {
  color: #fff;
  border-color: #fff
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-pinterest {
  background-color: #e60023
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-pinterest,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-pinterest {
  color: #e60023;
  border-color: #e60023
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-instagram {
  background-color: #e1306c
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-instagram,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-instagram {
  color: #e1306c;
  border-color: #e1306c
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-youtube {
  background-color: #ff0000
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-youtube,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-youtube {
  color: #ff0000;
  border-color: #ff0000
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tumblr {
  background-color: #35465c
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tumblr,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tumblr {
  color: #4a6180;
  border-color: #4a6180
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-linkedin {
  background-color: #0077b5
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-linkedin,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-linkedin {
  color: #0077b5;
  border-color: #0077b5
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tiktok {
  background-color: #010101
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-tiktok,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-tiktok {
  color: #fff;
  border-color: #fff
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-whatsapp {
  background-color: #25d366
}

.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-border .element-social-follow-profile--medium-whatsapp,.strip-container--color-inverse .element-social-follow--color-brand.element-social-follow--style-icononly .element-social-follow-profile--medium-whatsapp {
  color: #25d366;
  border-color: #25d366
}

.strip-container--color-inverse .image-gallery__main:has(.image-gallery__slides :focus-visible) {
  outline: 1px dotted #eb8113;
  outline-offset: 1px
}

.strip-container--color-inverse .image-gallery__thumbnail-item {
  border-color: rgba(163,163,163,0.3)
}

.strip-container--color-inverse .image-gallery__thumbnail-item:hover {
  border-color: rgba(163,163,163,0.6)
}

.strip-container--color-inverse .image-gallery__thumbnail-item.image-gallery__thumbnail-item--active {
  border-color: #eb8113
}

.strip-container--color-inverse .element-accordion--style-box .strip-container--color-inverse .element-accordion__heading {
  background-color: #eb8113;
  color: #fff;
  border-color: #bc670f
}

.strip-container--color-inverse .element-accordion--style-box .strip-container--color-inverse .element-accordion__item {
  background-color: rgba(163,163,163,0.05)
}

.strip-container--color-inverse .element-accordion--style-box .strip-container--color-inverse .element-accordion__content {
  border-color: #bc670f
}

.strip-container--color-inverse .table.table--header.table--border th,.strip-container--color-inverse .table.table--header.table--striped th {
  background-color: #eb8113;
  color: #fff
}

.strip-container--color-inverse .table.table--border tr,.strip-container--color-inverse .table.table--striped tr td {
  border-top: 1px solid rgba(163,163,163,0.1)
}

.strip-container--color-inverse .table.table--striped tr:nth-child(odd) td {
  background-color: rgba(163,163,163,0.05)
}

.strip-container--color-inverse .table.table--hover tr:hover td {
  background-color: rgba(163,163,163,0.15)
}

.strip-container--color-inverse .table.table--nowrap {
  white-space: nowrap
}

.strip-container--color-inverse .responsive-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(163,163,163,0.3)
}

.strip-container--color-inverse .handsontable .table--header.table--border tr:first-child td,.strip-container--color-inverse .handsontable .table--header.table--striped tr:first-child td {
  background-color: #eb8113;
  color: #fff
}

.strip-container--color-inverse .product,.strip-container--color-inverse .product__description,.strip-container--color-inverse .product-page__description,.strip-container--color-inverse .product-page__social {
  border-color: rgba(163,163,163,0.2)
}

.strip-container--color-inverse .columns .product .product,.strip-container--color-inverse .image-gallery__thumbnail-item {
  border-radius: 0
}

.strip-container--color-inverse .product__free-shipping-motivator {
  color: #eb8113
}

.strip-container--color-inverse .product-gallery--empty {
  border: 1px solid rgba(163,163,163,0.3);
  color: rgba(163,163,163,0.5)
}

.strip-container--color-inverse .product-gallery__content {
  background: #323232
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__content {
  border-color: rgba(163,163,163,0.3)
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__content:hover,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__content:hover {
  background: #414141;
  border-color: rgba(163,163,163,0.6)
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__content:focus-within,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__content:focus-within {
  background: #414141;
  border-color: rgba(163,163,163,0.6)
}

.strip-container--color-inverse .product-gallery--style-image-only .product-gallery__info-container {
  background-color: rgba(65,65,65,0.9)
}

.strip-container--color-inverse .product-gallery__price {
  color: #818181
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__expand-container button,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__expand-container button,.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__expand-container select,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__expand-container select {
  height: 32px;
  box-sizing: border-box
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover,.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -47px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within,.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -47px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-options .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-options .product-gallery__expand-container {
  height: 32px;
  margin-top: -32px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover {
  margin-bottom: -89px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:hover .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within {
  margin-bottom: -89px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__content:focus-within .product-gallery__expand-container {
  margin-top: 15px
}

.strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container,.strip-container--color-inverse .product-gallery--style-clean .product-gallery__item--has-button.product-gallery__item--has-options .product-gallery__expand-container {
  height: 74px;
  margin-top: -74px
}

.is-touch-device .strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item .product-gallery__content:hover,.is-touch-device .strip-container--color-inverse .product-gallery--style-clean .product-gallery__item .product-gallery__content:hover {
  margin-bottom: 0
}

.is-touch-device .strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item .product-gallery__content:focus-within,.is-touch-device .strip-container--color-inverse .product-gallery--style-clean .product-gallery__item .product-gallery__content:focus-within {
  margin-bottom: 0
}

.is-touch-device .strip-container--color-inverse .product-gallery--style-bordered .product-gallery__item .product-gallery__expand-container,.is-touch-device .strip-container--color-inverse .product-gallery--style-clean .product-gallery__item .product-gallery__expand-container {
  height: auto;
  margin-top: 15px;
  opacity: 1;
  visibility: visible
}

.strip-container--color-inverse .product-gallery-filter__button {
  color: #eb8113
}

.strip-container--color-inverse .btn__default-color {
  outline-color: #eb8113
}

.strip-container--color-inverse .btn__default-color.btn-style-flat {
  background: #eb8113;
  color: #fff
}

.strip-container--color-inverse .btn__default-color.btn-style-flat:hover:not(:disabled),.strip-container--color-inverse .btn__default-color.btn-style-flat.btn__active {
  background: #f09a41;
  color: #fff
}

.strip-container--color-inverse .btn__default-color.btn-style-border {
  background: transparent;
  border: 2px solid #eb8113;
  color: #eb8113
}

.strip-container--color-inverse .btn__default-color.btn-style-border:hover:not(:disabled),.strip-container--color-inverse .btn__default-color.btn-style-border.btn__active {
  color: #fff;
  background: #eb8113
}

.strip-container--color-inverse .btn__default-color.btn-style-default {
  background: #eb8113;
  color: #fff
}

.strip-container--color-inverse .btn__default-color.btn-style-default:hover:not(:disabled),.strip-container--color-inverse .btn__default-color.btn-style-default.btn__active {
  background: #f09a41;
  color: #fff
}

.strip-container--color-inverse .btn--default-color {
  color: #eb8113
}

.strip-container--color-inverse .icon-image__inner {
  background-color: #eb8113
}

.strip-container--color-inverse .image-is-square .icon-image,.strip-container--color-inverse .image-is-rounded .icon-image,.strip-container--color-inverse .image-is-round .icon-image {
  background-color: #eb8113
}

.strip-container--color-inverse .image-is-square .icon-image__inner,.strip-container--color-inverse .image-is-rounded .icon-image__inner,.strip-container--color-inverse .image-is-round .icon-image__inner {
  background-color: #323232
}

@media (max-width: 960px) {
  .strip-container--color-inverse {
      line-height:1.425
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse {
      line-height:1.35;
      font-size: 1.37rem
  }
}

.strip-container--color-inverse a {
  color: #eb8113;
  text-decoration: none
}

.strip-container--color-inverse a:hover {
  color: #f09a41;
  text-decoration: underline
}

.strip-container--color-inverse p,.strip-container--color-inverse ol,.strip-container--color-inverse ul {
  margin-top: 0;
  margin-bottom: .4em
}

.strip-container--color-inverse span[style*='color'] a {
  color: inherit
}

.strip-container--color-inverse button {
  line-height: 1.5
}

.strip-container--color-inverse .text-small {
  font-size: 1.05rem
}

.strip-container--color-inverse .accent-text-color {
  color: #eb8113
}

.strip-container--color-inverse .accent-background {
  color: #fff;
  background-color: #eb8113
}

.strip-container--color-inverse .accent-background a {
  color: #fff
}

.strip-container--color-inverse .accent-border-link-color {
  border-color: #eb8113
}

.strip-container--color-inverse .accent-border-color {
  border-color: #a3a3a3
}

.strip-container--color-inverse h1,.strip-container--color-inverse h2,.strip-container--color-inverse h3,.strip-container--color-inverse h4,.strip-container--color-inverse h5,.strip-container--color-inverse h6 {
  line-height: 1.1;
  color: #eb8113;
  font-family: "Source Sans Pro","sans-serif";
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .strip-container--color-inverse h1,.strip-container--color-inverse h2,.strip-container--color-inverse h3,.strip-container--color-inverse h4,.strip-container--color-inverse h5,.strip-container--color-inverse h6 {
      line-height:1.1
  }
}

@media (max-width: 960px) {
  .strip-container--color-inverse h1,.strip-container--color-inverse h2,.strip-container--color-inverse h3,.strip-container--color-inverse h4,.strip-container--color-inverse h5,.strip-container--color-inverse h6 {
      line-height:1.1
  }
}

.strip-container--color-inverse h1 a,.strip-container--color-inverse h2 a,.strip-container--color-inverse h3 a,.strip-container--color-inverse h4 a,.strip-container--color-inverse h5 a,.strip-container--color-inverse h6 a {
  text-decoration: underline;
  color: #eb8113
}

.strip-container--color-inverse h1 a:hover,.strip-container--color-inverse h2 a:hover,.strip-container--color-inverse h3 a:hover,.strip-container--color-inverse h4 a:hover,.strip-container--color-inverse h5 a:hover,.strip-container--color-inverse h6 a:hover {
  color: #f09a41
}

.strip-container--color-inverse .product-page .product__price__price {
  color: #eb8113
}

.strip-container--color-inverse .heading-200 {
  font-size: 6rem;
  margin-top: 3.3rem;
  margin-bottom: 1.2rem
}

@media (max-width: 960px) {
  .strip-container--color-inverse .heading-200 {
      font-size:4.5rem;
      margin-top: 2.85rem;
      margin-bottom: 1.05rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse .heading-200 {
      font-size:3.75rem;
      margin-top: 2.625rem;
      margin-bottom: .975rem
  }
}

.strip-container--color-inverse .heading-150 {
  font-size: 4.5rem;
  margin-top: 2.85rem;
  margin-bottom: 1.05rem
}

@media (max-width: 960px) {
  .strip-container--color-inverse .heading-150 {
      font-size:3.5rem;
      margin-top: 2.55rem;
      margin-bottom: .95rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse .heading-150 {
      font-size:3rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse .heading-130 {
  font-size: 3.9rem;
  margin-top: 2.67rem;
  margin-bottom: .99rem
}

@media (max-width: 960px) {
  .strip-container--color-inverse .heading-130 {
      font-size:3.1rem;
      margin-top: 2.43rem;
      margin-bottom: .91rem
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse .heading-130 {
      font-size:2.7rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse .heading-120 {
  font-size: 3.6rem;
  margin-top: 2.58rem;
  margin-bottom: .96rem
}

@media (max-width: 960px) {
  .strip-container--color-inverse .heading-120 {
      font-size:2.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse .heading-120 {
      font-size:2.55rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse h1,.strip-container--color-inverse .heading-100 {
  font-size: 3rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-inverse h1,.strip-container--color-inverse .heading-100 {
      font-size:2.5rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse h1,.strip-container--color-inverse .heading-100 {
      font-size:2.25rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse h2,.strip-container--color-inverse .heading-85 {
  font-size: 2.55rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-inverse h2,.strip-container--color-inverse .heading-85 {
      font-size:2.2rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse h2,.strip-container--color-inverse .heading-85 {
      font-size:2.02rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse h3,.strip-container--color-inverse .heading-70 {
  font-size: 2.1rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-inverse h3,.strip-container--color-inverse .heading-70 {
      font-size:1.9rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse h3,.strip-container--color-inverse .heading-70 {
      font-size:1.8rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.strip-container--color-inverse h4 {
  font-size: 1.44rem;
  margin-top: .8em;
  margin-bottom: .3em
}

@media (max-width: 960px) {
  .strip-container--color-inverse h4 {
      font-size:1.46rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

@media (max-width: 768px) {
  .strip-container--color-inverse h4 {
      font-size:1.47rem;
      margin-top: .8em;
      margin-bottom: .3em
  }
}

.background {
  display: none
}

.header-wrap {
  
  display: flex;
  
  padding: 20px;
  background: #333333;

}

.header-wrap .header {
  flex: 1
}

.header-wrap .menu {
  align-self: center
}

.header-wrap .menu>.menu-item>.menu-link {
  color: #a3a3a3
}

.header-wrap .menu>.menu-item>.menu-link:focus {
  box-shadow: 0 2px 0 0 #a3a3a3
}

.header-wrap .menu>.menu-item>.menu-link {
  color: #f0f0f0;
  font-weight: bold;
  background: transparent;
  box-shadow: 0 2px 0 0 #eb8113
}

.header-wrap .menu .submenu {
  margin-top: 20px
}

.header-wrap .menu .submenu .menu-has-submenu .submenu {
  margin-top: 0
}

.header-wrap .menu .submenu>.menu-item .menu-link {
  padding: 20px 30px 20px 20px;
  color: #fff;
  background: #eb8113;
  transition: 150ms ease;
  border-bottom: 2px solid #f09a41
}



.header-wrap .menu .submenu>.menu-item .menu-link:focus,.header-wrap .menu .submenu>.menu-item:hover>.menu-link,.header-wrap .menu .submenu>.menu-item.menu-is-hover>.menu-link {
  background: #f09a41
}

.header-wrap .menu .submenu>.menu-item .menu-link:active {
  background: #f3b371
}

.header-title {
  text-transform: uppercase;
  font-size: 2em;
  line-height: 1;
  color: #f0f0f0
}

.header-logo {
  float: left
}

.menu {
  margin-left: auto;
  margin-right: 20px
}

.is-menu-vertical-overflow .menu {
  float: left;
  margin-top: 10px;
  margin-left: -12px
}

.is-menu-vertical-overflow .menu-item:first-child {
  margin-left: 12px
}

.is-menu-multiline .menu {
  width: 100%
}

.is-menu-multiline .header-wrap .menu .submenu {
  margin-top: 0
}

.menu-is-desktop .menu-clone .menu {
  display: none
}

.submenu-is-opened .submenu>.menu-item:hover .menu-link {
  background: none
}

.menu-clone {
  background: #383838
}



.menu-clone .menu-item {
  text-align: center
}

.menu-clone .menu-item .arrow {
  padding: 15px;
  line-height: 1.5rem;
  background: none
}

.menu-clone .menu-item .arrow:hover {
  background: none
}

.menu-clone .menu-has-submenu>.menu-link {
  padding-left: 40px
}

.menu-clone .menu-link {
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.menu-clone >.menu-link span {
  color: #f0f0f0;
  font-weight: bold;
  background: transparent;
  box-shadow: 0 2px 0 0 #eb8113
}

.menu-clone .submenu>.menu-item>.menu-link {
  padding-top: 15px;
  padding-bottom: 15px
}

.menu-clone .submenu>.menu-item:not(.menu-has-submenu)>.menu-link {
  padding-right: 15px
}




  .content-width-wrapper {
      max-width:1096px;
      margin: auto;
  width: 100%;
  }

  .is-sidebar main .content-width-wrapper {
      max-width: 1096px
  }

  footer .content-width-wrapper {
      max-width: 1096px
  }


.breadcrumbs {
  margin-top: 0
}

.content-wrap {
  margin: 0 auto
}

.section {
  padding: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (max-width: 960px) {
  .section {
      padding:15px
  }
}

@media (max-width: 720px) {
  .section {
      padding:7.5px
  }
}

.light {
  background-color: #f9f9f9;
  transition: 0.3s;
}
.dark {
  background-color: #1a1a1a;
  transition: 0.3s;
  color: #dbdbdb;
}

@media (min-width: 960px) {
  .is-sidebar .light .content-width-wrapper {
      max-width:1096px
  }

  .is-sidebar .light .content-width-wrapper>section,.is-sidebar .light .content-width-wrapper>aside {
      float: left
  }

  .is-sidebar .light .content-width-wrapper>section {
      width: 70%;
      max-width: 767px
  }

  .is-sidebar .light .content-width-wrapper>aside {
      width: 30%;
      max-width: 329px
  }

  .is-sidebar footer .content-width-wrapper {
      max-width: 1096px
  }
}

.is-sidebar .light {
  padding-top: 15px;
  padding-bottom: 15px
}

.is-sidebar .section-sidebar {
  border-top: 3px solid #eb8113
}

.body {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.body>:not(.light) {
  flex: 0 0 auto
}

.light {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto
}

footer {
  background: #333333
}

@media (max-width: 960px) {
  .is-sidebar .section-content,.is-sidebar .section-sidebar,.is-sidebar .light {
      max-width:none;
      width: auto;
      float: none
  }

  .is-sidebar .section-content {
      border-right: none
  }
}

.slideshow .bx-wrapper .slideshow-slide {
  display: flex;
  flex-direction: column
}

#variable-loaded {
  color: #fc0
}

.variable-value-background-color {
  color: #333333
}

.variable-value-paragraph-color {
  color: #707070
}

.variable-value-paragraph-link-color {
  color: #eb8113
}

.variable-value-heading-color {
  color: #eb8113
}

.variable-value-heading-link-color {
  color: #eb8113
}

.variable-value-menu-text-color {
  color: #a3a3a3
}

.variable-value-menu-text-link-color {
  color: #eb8113
}

.variable-value-footer-text-color {
  color: #b1b0ac
}

.variable-value-footer-text-link-color {
  color: #eb8113
}
/* invisable contact form box */
.hidden,.visible-lt200-block,.visible-lt200-inline,.visible-lt200-inline-block{display:none!important}

/* Neem met ons contact op Form */
.element-form-group{
    margin:0 0 .8em
}
.element-form-group:after, .element-form-group:before{
    content:" ";display:table
}
.element-form-group:after{clear:both}
.element-form-label{box-sizing:border-box;float:left;font-weight:700;padding-right:2em;padding-top:.5em;width:25%}
.element-form-label.element-form-is-error{color:#d9534f}
.element-form-content{box-sizing:border-box;float:left;width:75%}
.element-form-content .element-form-input-text{
    width:100%
}
.element-form-input-text{
    border:1px solid;
    border-radius:2px;
    box-sizing:border-box;
    max-width:400px;
    padding:.5rem 1rem;
    transition:background-color .1s ease,
    border-color .1s ease
}
.element-form-input-text:focus{
    outline:0
}
.element-form-input-text::-webkit-input-placeholder{
    color:currentColor;
    font-style:italic;
    opacity:.7
}
.element-form-input-text::-moz-placeholder{color:currentColor;font-style:italic;opacity:.7}
.element-form-input-text::placeholder{color:currentColor;font-style:italic;opacity:.7}
select.element-form-input-text{background-position:right .7em top 50%,0 0;background-repeat:no-repeat,repeat;background-size:.65em auto,100%;padding-right:1.5em}
select.element-form-input-text::-ms-expand{display:none}

.element-form-well{border-radius:.2em;padding:1em}

.lt400 .element-form-content,.lt400 .element-form-label,.lt400 .element-form-submit{float:none;margin-left:0;text-align:left;width:auto}
.lt400 .element-form-offset{margin-left:0}
@media screen and (max-width:767px){input#location{max-width:215px}
}
.heading{margin-bottom:0}
.heading.node-is-first-child h1{margin-top:0}
.heading.node-is-last-child h1{margin-bottom:0}
.heading__no-margin{margin-top:0!important}
.simple-root{margin-bottom:0}
.simple-root:not(.node-is-last-child):not(.tree-container__empty){margin-bottom:1em}
.simple-root:not(.node-is-first-child):not(.tree-container__empty){margin-top:1em}
@media (min-width:641px){.column>.node-is-last-child,.simple-root>.node-is-last-child,.strip-container__content>.node-is-last-child{margin-bottom:0!important}
.tree-horizontal{align-items:stretch;display:flex;flex-direction:row;margin-left:-12px;margin-right:-12px;width:auto!important}
.tree-horizontal>.tree-node{min-width:0;padding-left:12px;padding-right:12px}
.tree-horizontal>.columns__node--separator-left{border-left-style:solid;border-left-width:2px}
.tree-horizontal>.columns__node--separator-left.node-is-first-child{margin-left:12px}
.tree-horizontal>.columns__node--separator-right{border-right-style:solid;border-right-width:2px}
.tree-horizontal>.columns__node--separator-right.node-is-last-child{margin-right:12px}
}
@media (max-width:640px){.tree-horizontal>.tree-node{display:block!important;width:100%!important}
}
.is-backend .columns__separator-remove-wrap{display:none;position:absolute;top:0;z-index:1599}
.is-backend .columns__separator-remove-wrap--left{left:-9px}
.is-backend .columns__separator-remove-wrap--right{right:-9px}
.is-backend .tree-horizontal:hover .columns__node--separator-left .columns__separator-remove-wrap--left,.is-backend .tree-horizontal:hover .columns__node--separator-right .columns__separator-remove-wrap--right{display:flex}
.columns--is-removing-child>.tree-node:not(.columns__node--is-removing){flex-grow:calc(var(--column-weight)*100)}
.column{margin-bottom:0}
.poll-bar{max-width:400px;position:relative}
.poll-width{border:1px solid transparent;border-radius:3px;box-sizing:border-box;font-size:.9em;min-width:3em;padding:.2em .5em}
.poll-results .element-form-label{font-weight:400}
.poll-results .poll-vote-count{font-weight:700}


/* social buttons */
   
    .element-social-share .share-button-container{display:inline-flex;flex-wrap:wrap;margin-right:-5px}
    .element-social-share .share-button-container--stacked{flex-direction:column}
    .element-social-share .share-button-container--stacked .share-button{width:auto}
    .element-social-share .share-button-container--align-left{justify-content:flex-start}
    .element-social-share .share-button-container--align-center{justify-content:center}
    .element-social-share .share-button-container--align-right{justify-content:flex-end}
    .element-social-share .share-button{border-radius:3px;box-shadow:0 1px 3px rgba(0,0,0,.2);box-sizing:border-box;display:flex;height:2em;line-height:2em;margin-bottom:5px;margin-right:5px;overflow:hidden;padding-right:.8em;text-decoration:none;transition:background-color .2s ease,box-shadow .2s ease;width:auto}
    .element-social-share .share-button:hover{box-shadow:0 1px 4px rgba(0,0,0,.3)}
    .element-social-share .share-button:focus{outline:1px solid currentcolor;outline-offset:2px}
    .element-social-share .share-button:active{box-shadow:0 1px 4px transparent,inset 0 1px 2px rgba(0,0,0,.15)}
    .element-social-share .share-button--wrapper{border:1px solid rgba(0,0,0,.1);box-shadow:none!important;display:block;line-height:0;padding:calc(1em - 11px);text-align:left}
    .element-social-share .share-button--wrapper.share-button--facebook-like{border:0;padding:1px 0 0}
    .element-social-share .share-button--mini,.element-social-share .share-button-container--buttons-mini .share-button{padding:0;width:2em}
    .element-social-share .share-button--mini .share-button__icon,.element-social-share .share-button-container--buttons-mini .share-button .share-button__icon{background:transparent;margin:0}
    .element-social-share .share-button--mini .share-button__label,.element-social-share .share-button-container--buttons-mini .share-button .share-button__label{display:none}
    .element-social-share .share-button__icon{background:rgba(0,0,0,.1);flex:0 0 auto;font-size:1.1em;line-height:1.81818182em;margin-right:.72727273em;text-align:center;width:1.81818182em}
    .element-social-share .share-button__label{flex:1 0 auto;font-weight:700;text-align:center}
    .element-social-share .share-button--facebook{background-color:#3b5998;text-decoration:none!important}
    .element-social-share .share-button--facebook .share-button__icon,.element-social-share .share-button--facebook .share-button__label{color:#fff!important}
    .element-social-share .share-button--facebook:hover{background-color:#344e86}
    .element-social-share .share-button--facebook:active{background-color:#2d4373}
    .element-social-share .share-button--twitter{background-color:#1a1a1a;text-decoration:none!important}
    .element-social-share .share-button--twitter .share-button__icon,.element-social-share .share-button--twitter .share-button__label{color:#fff!important}
    .element-social-share .share-button--twitter:hover{background-color:#0d0d0d}
    .element-social-share .share-button--twitter:active{background-color:#000}
    .element-social-share .share-button--whatsapp{background-color:#28c039;text-decoration:none!important}
    .element-social-share .share-button--whatsapp .share-button__icon,.element-social-share .share-button--whatsapp .share-button__label{color:#fff!important}
    .element-social-share .share-button--whatsapp:hover{background-color:#23ab33}
    .element-social-share .share-button--whatsapp:active{background-color:#1f962d}
    .element-social-share .share-button--pinterest{background-color:#bd081c;text-decoration:none!important}
    .element-social-share .share-button--pinterest .share-button__icon,.element-social-share .share-button--pinterest .share-button__label{color:#fff!important}
    .element-social-share .share-button--pinterest:hover{background-color:#a50718}
    .element-social-share .share-button--pinterest:active{background-color:#8c0615}
    .element-social-share .share-button--linkedin{background-color:#0077b5;text-decoration:none!important}
    .element-social-share .share-button--linkedin .share-button__icon,.element-social-share .share-button--linkedin .share-button__label{color:#fff!important}
    .element-social-share .share-button--linkedin:hover{background-color:#00669c}
    .element-social-share .share-button--linkedin:active{background-color:#005582}
    .element-social-follow--size-s{margin:-3px}
    .element-social-follow--size-s .element-social-follow-profile{font-size:16px;height:24px;line-height:24px;margin:3px;width:24px}
    .element-social-follow--size-s.element-social-follow--roundness-round .element-social-follow-profile{font-size:14px}
    .element-social-follow--size-s.element-social-follow--style-icononly .element-social-follow-profile{font-size:18px}
    .element-social-follow--size-s.element-social-follow--style-solid.element-social-follow--roundness-rounded .element-social-follow-profile--medium-facebook,.element-social-follow--size-s.element-social-follow--style-solid.element-social-follow--roundness-square .element-social-follow-profile--medium-facebook{font-size:20px}
    .element-social-follow--size-s.element-social-follow--style-solid.element-social-follow--roundness-round .element-social-follow-profile--medium-facebook{font-size:19px}
    .element-social-follow--size-m{margin:-4px}
    .element-social-follow--size-m .element-social-follow-profile{font-size:22px;height:32px;line-height:32px;margin:4px;width:32px}
    .element-social-follow--size-m.element-social-follow--roundness-round .element-social-follow-profile{font-size:20px}
    .element-social-follow--size-m.element-social-follow--style-icononly .element-social-follow-profile{font-size:26px}
    .element-social-follow--size-m.element-social-follow--style-solid.element-social-follow--roundness-rounded .element-social-follow-profile--medium-facebook,.element-social-follow--size-m.element-social-follow--style-solid.element-social-follow--roundness-square .element-social-follow-profile--medium-facebook{font-size:27px}
    .element-social-follow--size-m.element-social-follow--style-solid.element-social-follow--roundness-round .element-social-follow-profile--medium-facebook{font-size:26px}
    .element-social-follow--size-l{margin:-6px}
    .element-social-follow--size-l .element-social-follow-profile{font-size:32px;height:48px;line-height:48px;margin:6px;width:48px}
    .element-social-follow--size-l.element-social-follow--roundness-round .element-social-follow-profile{font-size:28px}
    .element-social-follow--size-l.element-social-follow--style-icononly .element-social-follow-profile{font-size:38px}
    .element-social-follow--size-l.element-social-follow--style-solid.element-social-follow--roundness-rounded .element-social-follow-profile--medium-facebook,.element-social-follow--size-l.element-social-follow--style-solid.element-social-follow--roundness-square .element-social-follow-profile--medium-facebook{font-size:40px}
    .element-social-follow--size-l.element-social-follow--style-solid.element-social-follow--roundness-round .element-social-follow-profile--medium-facebook{font-size:38px}
    .element-social-follow--size-xl{margin:-9px}
    .element-social-follow--size-xl .element-social-follow-profile{font-size:51px;height:75px;line-height:75px;margin:9px;width:75px}
    .element-social-follow--size-xl.element-social-follow--roundness-round .element-social-follow-profile{font-size:45px}
    .element-social-follow--size-xl.element-social-follow--style-icononly .element-social-follow-profile{font-size:59px}
    .element-social-follow--size-xl.element-social-follow--style-solid.element-social-follow--roundness-rounded .element-social-follow-profile--medium-facebook,.element-social-follow--size-xl.element-social-follow--style-solid.element-social-follow--roundness-square .element-social-follow-profile--medium-facebook{font-size:63px}
    .element-social-follow--size-xl.element-social-follow--style-solid.element-social-follow--roundness-round .element-social-follow-profile--medium-facebook{font-size:60px}
    .element-social-follow-profile{display:inline-block;overflow:hidden;text-decoration:none!important;transition:opacity .15s ease;vertical-align:top}
    .element-social-follow-profile:focus{outline:1px dotted currentColor;outline:1px solid #469fd5;outline-offset:0;outline-offset:2px}
    .element-social-follow-profile:focus:not(:focus-visible){outline:none}
    .element-social-follow-profile:focus,.element-social-follow-profile:hover{opacity:.8}
    .element-social-follow-profile:active{opacity:.7}
    .element-social-follow-profile [class^=website-rendering-icon]{align-items:center;display:flex;height:100%;justify-content:center}
    .element-social-follow-profile [class^=website-rendering-icon]:before{margin:0;text-align:center;width:100%}
    .element-social-follow-profile.is-hidden{display:none}
    .element-social-follow--style-border .element-social-follow-profile{border-style:solid;border-width:2px}
    .element-social-folow--style-icononly .element-social-follow-profile{background:none!important}
    .element-social-follow--roundness-rounded .element-social-follow-profile{border-radius:.15em}
    .element-social-follow--roundness-round .element-social-follow-profile{border-radius:50%}
    .element-social-follow--style-solid .website-rendering-icon-facebook:before{margin-bottom:-1px;margin-top:auto}
    .element-social-follow--style-solid.element-social-follow--roundness-rounded .website-rendering-icon-facebook:before,.element-social-follow--style-solid.element-social-follow--roundness-square .website-rendering-icon-facebook:before{margin-left:.15em}
    .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile{color:#fff}
    .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-instagram{background:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='132' height='132'%3E%3Cdefs%3E%3CradialGradient xlink:href='%23a' id='c' cx='158.429' cy='578.088' r='65' fx='158.429' fy='578.088' gradientTransform='matrix(0 -1.98198 1.8439 0 -1031.402 454.004)' gradientUnits='userSpaceOnUse'/%3E%3CradialGradient xlink:href='%23b' id='d' cx='147.694' cy='473.455' r='65' fx='147.694' fy='473.455' gradientTransform='matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)' gradientUnits='userSpaceOnUse'/%3E%3ClinearGradient id='b'%3E%3Cstop offset='0' stop-color='%233771c8'/%3E%3Cstop offset='.128' stop-color='%233771c8'/%3E%3Cstop offset='1' stop-color='%2360f' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='a'%3E%3Cstop offset='0' stop-color='%23fd5'/%3E%3Cstop offset='.1' stop-color='%23fd5'/%3E%3Cstop offset='.5' stop-color='%23ff543e'/%3E%3Cstop offset='1' stop-color='%23c837ab'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='url(%23c)' d='M0 0h132v132H0z'/%3E%3Cpath fill='url(%23d)' d='M0 0h132v132H0z'/%3E%3C/svg%3E") 50%;background-size:cover}
    .element-social-follow--color-brand.element-social-follow--style-solid .element-social-follow-profile--medium-tiktok{text-shadow:-.05em -.05em #69c9d0,.05em .05em #ee1d52}
    .element-social-follow .element-content{display:inline-block}
    .element-social-follow-placeholder,.element-social-follow.is-empty>.element-content{display:none}
    .element-social-follow.is-empty .element-social-follow-placeholder{display:block}
    .element-separator-padding{padding:5px 0}
    .element-separator{background-color:transparent;border:0 solid}
    .element-separator.element-separator{border-top-width:1px}
    .element-separator.element-separator--normal{border-top-width:2px}
    .element-separator.element-separator--thick{border-top-width:3px}
    .element-separator.element-separator--extra-thick{border-top-width:5px}
    .element-separator--dotted{border-style:dotted}
    .element-separator--dashed{border-style:dashed}
    .element-separator--double{border-style:double}
    .element-separator--double.element-separator{border-top-width:3px}
    .element-separator--double.element-separator--normal{border-top-width:6px}
    .element-separator--double.element-separator--thick{border-top-width:9px}
    .element-separator--double.element-separator--extra-thick{border-top-width:15px}

/* Neem contact met ons op from */
.btn-wrap{display:inline-block;position:relative}
.btn,.btn-container a:hover{text-decoration:none!important}
.btn{align-items:center;background:transparent;border:0;cursor:pointer;display:inline-flex;justify-content:center;text-align:center;transition:background .2s ease-out}
.btn:focus,.btn:hover{text-decoration:none}
.btn:active:not(:disabled){box-shadow:inset 0 2px 3px rgba(0,0,0,.2)}
.btn .spinner{margin-left:.3em}
.btn:focus{outline:1px solid;outline-offset:2px}
.btn:focus:not(:focus-visible){outline:none}
.btn:disabled{opacity:.5}
.btn-style-border{background:transparent;text-decoration:none;transition:background-color .1s linear}
.btn-style-border:active{box-shadow:none}
.btn-sm{font-size:1em;padding:.3em .9em}
.btn-sm.btn--icon-only{padding:.3em .4em}
.btn-default{font-size:1.3em;padding:.5em .9em}
.btn-default.btn--icon-only{padding:.5em}
.btn-lg{font-size:1.8em;padding:.39em .9em}
.btn-lg.btn--icon-only{padding:.39em .5em}
.btn-is-full-width{box-sizing:border-box;overflow:visible;width:100%}
.is-backend .button .btn{cursor:text}
@media screen and (min-width:480px){.btn:not(.btn-is-full-width){text-align:center}
}
.btn-is-center{text-align:center}
.btn-is-center .btn-is-full-width{justify-content:center}
.btn__active{font-weight:700}
@media (max-width:450px){.website-button span{display:none}
.website-button a{display:block;margin-left:0;text-align:center}
}
/* list of "Veel gestelde vragen" */
.is-editor .element-accordion{-webkit-user-select:none;-moz-user-select:none;user-select:none}
.element-accordion .element-accordion__item{cursor:pointer;display:block;outline:none;position:relative}
.element-accordion .element-accordion__heading{list-style:none;padding:1.2rem 0;position:relative}
.element-accordion .element-accordion__heading::-webkit-details-marker,.element-accordion .element-accordion__heading::marker{display:none}
.element-accordion .element-accordion__heading h2,.element-accordion .element-accordion__heading h3,.element-accordion .element-accordion__heading h4{background:none;color:inherit!important;margin:0!important;padding:0!important}
.element-accordion .element-accordion__heading h4{font-weight:700!important}
.element-accordion .element-accordion__content--open-start{max-height:0;overflow:hidden}
.element-accordion .element-accordion__content--open-active{max-height:var(--computedHeight);transition:max-height .2s ease-out}
.element-accordion .element-accordion__content--close-start{max-height:var(--computedHeight)}
.element-accordion .element-accordion__content--close-active{max-height:0;overflow:hidden;transition:max-height .2s ease-out}
.element-accordion--align-icon-right .element-accordion__heading{padding-right:4rem!important}
.element-accordion--align-icon-right .element-accordion__icon{right:.8rem}
.element-accordion--align-icon-left .element-accordion__heading{padding-left:2.5rem!important}
.element-accordion--align-icon-left .element-accordion__icon{left:-7px}
.element-accordion .element-accordion__icon{font-size:1.8rem;position:absolute;top:50%;transform:translateY(-50%) rotate(0);transition:transform .25s ease}
.element-accordion .element-accordion__icon.website-rendering-icon-right-open{transform:translateY(-50%) rotate(90deg)}
.element-accordion .element-accordion__icon--plus{display:block;height:1.2rem;margin:0 .4em;width:1.2rem}
.element-accordion .element-accordion__icon--plus:after,.element-accordion .element-accordion__icon--plus:before{background-color:currentColor;border-radius:2px;content:"";position:absolute;transition:transform .25s ease-out}
.element-accordion .element-accordion__icon--plus:before{height:100%;left:50%;margin-left:-1px;top:0;width:2px}
.element-accordion .element-accordion__icon--plus:after{height:2px;left:0;margin-top:-1px;top:50%;width:100%}
.element-accordion .element-accordion__item[open] .element-accordion__icon.website-rendering-icon-right-open{transform:translateY(-50%) rotate(-90deg)}
.element-accordion .element-accordion__item[open] .element-accordion__icon--plus:before{transform:rotate(90deg)}
.element-accordion .element-accordion__item[open] .element-accordion__icon--plus:after{transform:rotate(180deg)}
.element-accordion .element-accordion__content-wrap{padding:1rem 0}
.element-accordion--style-border .element-accordion__item{border-bottom:1px solid}
.element-accordion--style-box{border-radius:3px;overflow:hidden}
.element-accordion--style-box .element-accordion__heading{border-bottom:1px solid;padding-left:1rem;padding-right:1rem}
.element-accordion--style-box .element-accordion__content{border:1px solid;border-top:none}
.element-accordion--style-box .element-accordion__content-wrap{padding:2rem 3.5rem}
.element-accordion--style-box.element-accordion--align-icon-left .element-accordion__heading{padding-left:4rem!important}
.element-accordion--style-box.element-accordion--align-icon-left .element-accordion__icon{left:1rem}
.text-align{
  text-align: center;
}
.text-align-left{
  text-align: left;
}
.font-size-x{
  font-size: x-large;
}
.height-50{
  height: 50px;
}
.width-9{
  width: 9%;
}
.width-14{
  width: 14.285714285714%;
}
.width-16{
  width: 16%;
}
.width-25{
  width: 25%;
}
.width-33{
  width: 33.333333333333%;
}
.width-34{
  width: 34%;
}
.width-50{
  width: 50%;
}
.width-66{
  width: 66%;
}
.width-71{
  width: 71.428571428571%;
}
.padding-top-120{
  padding-top: 120.05899705015%
}
.margin{
  margin: 8px;
}
.list{
  list-style: inside;
  padding-left: 3%;
}
.dark_mode_label {
  width: 65px;
  height: 30px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
      inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  display: none;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 62px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}
.wrapper{
  display: flex;
  flex-direction: row;
}
.item{
  grid-template-rows:40%, 60%;
}
img{
  width: 100%;
}
@media screen and (max-width: 768px) {
  .hiddenImg{
display: none;
  }
  }
  @media screen and (max-width: 825px) {
    .heading-text{
  text-align: center;
    }
    .button-center{
      justify-content: center;
      text-align: center;
    }
    .menu-link{
      display: none;
    }
    .header-wrap{
      padding: 0px;
      
    }
    
    }
    .header-scroll{
      position: sticky;
      top: 0px;
      transition: 0.3s;
      z-index: 1;
    }
    .header-scroll.down {
      top: -6rem;
      transition: 0.3s;
    }
    @media screen and (max-width: 725px) {
      .menu {
        gap: 0px;
        margin-right: 0px;
      }
      .header-wrap{
        padding-bottom: 10px;
        padding-top: 10px;
      }
      
    }